import styled from 'styled-components/macro';

export const CompanyAboutCardStyled = styled.div`
    background-color: white;

    .company-about {
        &__inner {
            white-space: pre-wrap;
            text-align: justify;
            padding: ${(props) => props.theme.space.xxl} ${(props) => props.theme.space.md}
                ${(props) => props.theme.space.lg};
        }
    }
`;
