import styled from 'styled-components/macro';
import { CompanyTileStyled } from '../CompanyTile';

export const TileGroupStyled = styled.div`
    background-color: white;
    box-shadow: 0px 4px 44px #edeef0;
    margin-bottom: ${(props) => props.theme.space.sm};
    border-radius: ${(props) => props.theme.radii.default};
    border: 2px solid ${(props) => (props.highlight ? props.theme.colors.royalBlue : 'white')};

    .tile-group {
        &__header {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            margin: 0;
            padding: 1.25rem;
            cursor: pointer;

            &:hover {
                .tile-group__title {
                    color: ${(props) => props.theme.colors.royalBlue};
                }
            }
        }

        &__toggle {
            margin-left: auto;
        }

        &__title {
            color: ${(props) => (props.highlight ? props.theme.colors.royalBlue : props.theme.colors.grayDark)};
            font-size: ${(props) => props.theme.fontSizes.sm};
            margin: 0;
            transition: ${(props) => props.theme.transitions.default};
            margin-right: ${(props) => props.theme.space.xs};
        }
    }

    ${CompanyTileStyled} {
        & + ${CompanyTileStyled} {
            margin-top: ${(props) => props.theme.space.xs};
        }
    }
`;

export const Content = styled.div`
    height: auto;
    transition: ${(props) => props.theme.transitions.default};
    will-change: all;

    > div {
        padding: 0 1.25rem 1.25rem;
    }
`;
