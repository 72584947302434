import styled from 'styled-components/macro';

export const ExpertiseRepeaterStyled = styled.div``;

export const ExpertiseRepeaterRow = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-wrap: nowrap;
`;
