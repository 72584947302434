import React, { useEffect } from 'react';
import { Router, navigate } from '@reach/router';

import { PageWrap, ContentContainer } from '../components/Layout';

//Routes
import { Shortlist } from './search/Shortlist';
import { NotFound } from '../routes/NotFound';

export const CompanyListing = (props) => {
    useEffect(() => {
        if (localStorage.getItem('is_admin') !== 'true') {
            navigate('/404');
        }
    }, []);

    return (
        <PageWrap>
            <ContentContainer>
                <Router primary={false}>
                    <Shortlist path='/' admin={props.admin} linkNewTab={false} />
                    <NotFound default />
                </Router>
            </ContentContainer>
        </PageWrap>
    );
};
