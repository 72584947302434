import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { navigate } from "@reach/router";
import { DocumentGroup } from "../../../components/DocumentGroup";
import {
  getDocumentCategories,
  editDocument,
  getDocumentAccessRequests,
} from "../../../actions/companyActions";
import { Modal } from "../../../components/Modal";
import { AddDocument } from "../../../components/DocumentForm/AddDocument";
import { Toolbar } from "../../../components/Toolbar";
import { Button } from "../../../components/Button";
import { TabHeader } from "../../../components/TabHeader";
import { TabContent } from "../../../components/Tabs/styled";
import { ReactComponent as Bell } from "./../../../icons/bell.svg";

export const DocumentsComponent = ({
  documents,
  company,
  getDocumentCategories,
  documentCategories,
  editDocument,
  editable,
  isRequesting,
  profileTypeId,
  notifications,
  getDocumentAccessRequests,
  ...props
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    getDocumentCategories(profileTypeId);
    getDocumentAccessRequests(company.id);
  }, [
    getDocumentCategories,
    getDocumentAccessRequests,
    profileTypeId,
    company,
  ]);

  const openAddDocumentModal = () => {
    setModalIsOpen(true);
  };

  const closeAddDocumentModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      {editable && <TabHeader heading="Corporate Documents" />}
      <TabContent noPadding={editable ? false : true}>
        {editable && (
          <>
            <Toolbar>
              <Button onClick={openAddDocumentModal}>Add New Document</Button>

              <div
                className="request-notification"
                onClick={() => {
                  navigate("documents/request-view");
                }}
              >
                <Bell />
                <p>DOCUMENT REQUESTS</p>
                <span className="numberCircle">{notifications}</span>
              </div>
            </Toolbar>
            <div className="guidance-note">
              <p>
                Control visibility of this document by selecting Private or
                Public.{" "}
              </p>
            </div>
          </>
        )}

        {!isRequesting ? (
          documentCategories.length && documents ? (
            <>
              {documentCategories.map((category) => {
                //filter docs by category
                let categoryDocuments = documents.filter(
                  (document) =>
                    document.category && document.category.id === category.id
                );

                let subCategoryDocuments = documents.filter(
                  (document) =>
                    document.category &&
                    document.category.parent_id === category.id
                );

                categoryDocuments =
                  categoryDocuments.concat(subCategoryDocuments);
                return categoryDocuments.length || editable ? (
                  <DocumentGroup
                    key={category.id}
                    title={category.name}
                    editable={editable}
                    documents={categoryDocuments}
                    editDocument={editDocument}
                    company={company}
                  />
                ) : (
                  ""
                );
              })}
            </>
          ) : (
            <Skeleton count={5} duration={0.5} />
          )
        ) : (
          <Skeleton count={5} duration={0.5} />
        )}

        {modalIsOpen && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeAddDocumentModal}
            width="800"
          >
            <AddDocument
              initialValues={document}
              onSuccessfulSubmit={closeAddDocumentModal}
            />
          </Modal>
        )}
      </TabContent>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profileTypeId: state.search.activeCompany.profile_type.id,
    documentCategories: state.companyProfile.documentCategories.filter(
      (d) => d.parent_id === 0
    ),
    isRequesting: state.companyProfile.isRequesting,
    notifications: state.companyProfile.document_access_requests.filter(
      (req) => req.is_viewed === 0
    ).length,
    company: state.search.activeCompany,
  };
};

export const Documents = connect(mapStateToProps, {
  getDocumentCategories,
  editDocument,
  getDocumentAccessRequests,
})(DocumentsComponent);
