import React from "react";
import styled from "styled-components/macro";

import { IconBoxStyled } from "../IconBox/styled";

export const ContextMenuStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.snow};
  padding: 0.25rem 0.85rem;
  padding-right: 0;
  left: 90px;
  width: 310px;
  position: fixed;
  height: 100vh;
  flex-shrink: 0;

  hr {
    border-top: 1px solid #5b6b88;
    opacity: 0.2;
    margin-left: 0;
    margin-right: 0;
  }

  ${IconBoxStyled} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .active-link {
    svg {
      color: ${(props) => props.theme.colors.royalBlue};
      rect {
        stroke: ${(props) => props.theme.colors.royalBlue};
      }
    }
  }
  .profile-share {
    margin-top: auto;
    margin-bottom: 32px;
    display: flex;
    jusitfy-content: center;
    align-items: center;

    ${IconBoxStyled} {
      margin-right: ${(props) => props.theme.space.xs};
      margin-left: ${(props) => props.theme.space.xs};
      padding: 0;
    }
    span {
      font-size: ${(props) => props.theme.fontSizes.md};
      color: ${(props) => props.theme.colors.navy};
      cursor: pointer;
    }
    :hover {
      svg {
        color: ${(props) => props.theme.colors.royalBlue};
      }
      span {
        color: ${(props) => props.theme.colors.royalBlue};
      }
    }
  }
`;

export const AccountBadge = styled((props) => {
  return (
    <div className={props.className}>
      <img
        src={
          props.logo ||
          `https://ui-avatars.com/api/?name=${props.name}&color=fff&background=000`
        }
        alt={props.name}
      />
      {props.name}
    </div>
  );
})`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${(props) => props.theme.colors.navy};
  font-size: ${(props) => props.theme.fontSizes.xl};
  margin-bottom: ${(props) => props.theme.space.lg};
  padding-right: ${(props) => props.theme.space.sm};
  margin-left: ${(props) => props.theme.space.xs};
  margin-top: 32px;
  img {
    width: 40px;
    height: 40px;
    border-radius: ${(props) => props.theme.radii.default};
    margin-right: ${(props) => props.theme.space.xs};
    object-fit: contain;
  }
`;
