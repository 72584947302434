import React, { useState } from "react";
import { CustomTable, Pagination, DotPage } from "./styled";
import { Button } from "components/Button";
import { ContentRowStyled } from "./styled";

const paginationInfo = (page, perPage, dataLength) => {
  const perpage = perPage;
  const totalItem = dataLength;
  const startIndex = page * perpage;
  const endIndex = startIndex + perpage;
  const maxPage = Math.ceil(totalItem / perpage);
  const arrayPage = new Array(maxPage).fill("");
  return {
    perpage: perPage,
    totalItem: totalItem,
    startIndex: startIndex,
    endIndex: endIndex,
    maxPage: maxPage,
    arrayPage: arrayPage,
  };
};
const getIndexData = (id, datas) => {
  const index = datas.findIndex((data) => {
    return data.id === id;
  });
  return index;
};
const lastIndexData = (id, datas) => {
  const index = getIndexData(id, datas);
  if (index === datas.length - 1) return true;
  return false;
};

export const CustomTableContainer = ({
  datas,
  perPage,
  Header,
  Content,
  ...props
}) => {
  console.log("renderTableCont", datas);
  const [page, setPage] = useState(0);
  const pageProps = paginationInfo(page, perPage, datas.length);

  const handleNext = () => {
    setPage((prev) => setPage(prev + 1));
  };
  const handlePrev = () => {
    setPage((prev) => setPage(prev - 1));
  };
  const handleJumpTo = (index) => {
    setPage(index);
  };

  return (
    <CustomTable>
      {<Header />}
      {datas.length > 0 &&
        datas
          .slice(pageProps.startIndex, pageProps.endIndex)
          .map((item, i) => (
            <Content
              item={item}
              i={i}
              prevItem={
                lastIndexData(item.id, datas)
                  ? null
                  : datas[getIndexData(item.id, datas) + 1]
              }
              {...props}
            />
          ))}
      {pageProps.arrayPage.length > 0 && (
        <Pagination>
          <Button
            height={"35px"}
            disabled={page <= 0 ? true : false}
            onClick={handlePrev}
          >
            Prev
          </Button>
          <DotPage>
            {pageProps.arrayPage.map((dot, i) => {
              return (
                <span
                  className={`${page === i ? "active" : ""}`}
                  onClick={() => handleJumpTo(i)}
                ></span>
              );
            })}
          </DotPage>
          <Button
            height={"35px"}
            disabled={page >= pageProps.maxPage - 1 ? true : false}
            onClick={handleNext}
          >
            Next
          </Button>
        </Pagination>
      )}
    </CustomTable>
  );
};

export const HeaderRow = ({ children, className, ...props }) => {
  return (
    <div className={`row ${className}`} {...props}>
      {children}
    </div>
  );
};

export const HeaderCollumn = ({ children, className, ...props }) => {
  return (
    <div className={`col ${className}`} {...props}>
      {children}
    </div>
  );
};

export const ContentRow = ({ children, className, ...props }) => {
  return (
    <ContentRowStyled className={`row ${className}`} {...props}>
      {children}
    </ContentRowStyled>
  );
};

export const ContentCollumn = ({ children, className, ...props }) => {
  return (
    <div className={`col ${className}`} {...props}>
      {children}
    </div>
  );
};
