import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components/macro";
import axios from "axios";

import { Heading } from "../../components/Heading";
import { CompanyCard } from "../../components/CompanyCard";
import { getCompanies } from "../../actions/companyActions";
import { Button } from "components/Button";
import { ReactComponent as DownloadIcon } from "../../icons/download.svg";

import "styles/loading.scss";
import { TabContent } from "../../components/Tabs/styled";

const ShortlistStyled = styled.div`
  padding: ${(props) =>
    props.padded ? `${props.theme.space.lg} ${props.theme.space.md}` : 0};

  ${(props) =>
    props.searchShortlist &&
    `
        margin: 0;
    `}

  .shortlisted-text {
    font-size: ${(props) => props.theme.fontSizes.xxl};
    line-height: 32px;
    margin-bottom: 0px;
  }
`;

export const ShortlistContainer = ({
  getCompanies,
  items,
  noHeading,
  padded,
  searchShortlist,
  handleRemoveButtonClick,
  linkNewTab,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!items.length && !searchShortlist) {
      getCompanies();
    }
  }, [items, getCompanies, searchShortlist]);

  const companies = items.length ? items : props.searchResult;
  const groupId = props.groupId;
  const companyId = props.companyId;

  const download = () => {
    setIsLoading(true);

    axios({
      url:
        process.env.REACT_APP_API_URL +
        `/groups/print-pdf/${groupId}?companyId=${companyId}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "shortlist.pdf");
      document.body.appendChild(link);
      link.click();
      setIsLoading(false);
    });
  };

  return (
    <TabContent>
      {!noHeading && (
        <Heading size="xl">
          {props.admin ? "Companies Admin" : "Companies Listings"}
        </Heading>
      )}
      <ShortlistStyled
        className="row"
        padded={padded}
        searchShortlist={searchShortlist}
      >
        {companies.length > 0 ? (
          Object.values(companies).map((item) => {
            return (
              <CompanyCard
                key={item.id}
                id={item.id}
                name={item.name}
                size={item.size}
                logo={item.logo_path}
                banner={item.banner_path}
                className={`col md-6 ${searchShortlist ? "lg-3" : "lg-4"}`}
                admin={props.admin}
                location={item.location}
                profileType={item.profile_type}
                handleRemoveButtonClick={handleRemoveButtonClick}
                linkNewTab={linkNewTab}
              />
            );
          })
        ) : (
          <div style={{ flexGrow: 1 }}>
            <Skeleton count={5} duration={1} />
          </div>
        )}
        {companies.length > 0 &&
        window.location.pathname !== "/accounts" &&
        window.location.pathname !== "/companies" ? (
          <div className="col xs-12">
            <p className="shortlisted-text">
              Download a detailed comparison of your shortlisted suppliers.
            </p>
            <Button as="span" btntype="iconInline" onClick={download}>
              <span className="iconInline">
                <DownloadIcon />
              </span>
              Download Shortlist
            </Button>
          </div>
        ) : (
          ""
        )}

        {isLoading ? (
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              top: 0,
              left: 0,
              zIndex: 9999,
            }}
          >
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </ShortlistStyled>
    </TabContent>
  );
};

ShortlistContainer.defaultProps = {
  items: [],
};

const mapStateToProps = (state) => {
  return {
    searchResult: state.search.searchResult,
    groupId: state.search.groupId,
    companyId: state.auth.current_company.id,
  };
};

export const Shortlist = connect(mapStateToProps, { getCompanies })(
  ShortlistContainer
);
