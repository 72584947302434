import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import styled from 'styled-components/macro';

import { SliderButton } from '../SliderButton';
import { ImageWrap, ImageSliderStyled, ImageSliderCaption, ImageSliderSubCaption, TextOverlay } from './styled';
import { theme } from '../../theme';

export const Image = styled.img`
    object-fit: ${(props) => (props.isPlaceholder ? 'contain' : 'cover')};
    background-color: ${(props) => (props.isPlaceholder ? '#e9ecf1' : 'transparent')};
    width: calc(100% + 20px);
    position: relative;
    height: 300px;

    ${theme.mediaQueries.xl} {
        height: 420px;
    }
`;

export const ImageSlider = ({ images, ...props }) => {
    const [swiper, setSwiper] = useState(null);

    const swiperParams = {
        loop: true,
        slidesPerView: 'auto',
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    };

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    return (
        <ImageWrap hasImage={images[0]}>
            {images.length > 1 ? (
                <ImageSliderStyled>
                    <SliderButton direction='prev' onClick={goPrev} />
                    <SliderButton direction='next' onClick={goNext} />

                    <Swiper {...swiperParams} getSwiper={setSwiper} shouldSwiperUpdate>
                        {images.length && images.map((image, i) => <Image key={i} src={image} alt='' />)}
                    </Swiper>
                </ImageSliderStyled>
            ) : (
                <Image
                    src={images[0] || process.env.PUBLIC_URL + '/img/placeholder-lg.jpg'}
                    alt=''
                    isPlaceholder={!images[0]}
                />
            )}

            <TextOverlay dark={!images[0]}>
                {props.heading && <ImageSliderCaption>{props.heading}</ImageSliderCaption>}
                {props.subheading && (
                    <ImageSliderSubCaption>
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${props.subheading}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {props.subheading}
                        </a>
                    </ImageSliderSubCaption>
                )}
            </TextOverlay>
        </ImageWrap>
    );
};
