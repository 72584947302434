import styled from "styled-components/macro";

export const UserFormStyled = styled.div`
    h3{
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #253858;
    }
    hr {
        border-top: 1px solid #E9ECF1;
        margin-top:30px;
        margin-bottom:30px;
    }
`