import styled from 'styled-components/macro';
import { theme } from '../../theme';
export const CardsWrapper = styled.div `

    
    border: 2px solid ${props=>props.checked ? props.theme.colors.mint : 'rgba(132, 146, 166, 0.2)'};
    background: white;
    border-radius: 5px;
    padding: 24px ${props=> props.small ? '16px' :'24px'};  
    width: ${props=>props.width ? props.width : '100%'};
    display: flex;
    flex-direction: column;
    
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '1.25rem'};
    color: ${(props) => props.theme.colors.gray};
    transition: ${(props) => props.theme.transitions.default};
    .card {
       
        .flex-row{
            display:flex;
            
            font-weight:500;
            margin: 0px 0px 16px 0px;
            span {
                font-size:9px;
                font-weight:400;
                margin-left:4px;
            }
            p{
                margin-right:40px;
            }
        }
        .scnd{
            margin: 0px;
        }
        .header{
            display:flex;
            p {
                color: ${theme.colors.navy};
                font-size: ${theme.fontSizes.lg};
                font-weight:500;
                margin: 0px 0px 16px 0px;
            }
            .current {
                margin-left:5%;
                color:#5cb85c;
            }
            
        }
        p{
            margin: 0px;
            font-size: ${theme.fontSizes.lg};

        }
        
       

    }
   
`;

export const CheckboxStyled = styled.label`
    float:right;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-family:'Roboto';
    color:${props=>props.theme.colors.grayLight};
    font-size:${props=>props.theme.fontSizes.md};

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &::checked .checkmark {
            background-color: ${(props) => props.theme.colors.royalBlue};
        }
    }

    .checkmark {
        position: absolute;
        top: ${(props) => (props.checkmarkTop ? props.checkmarkTop : '0px')};
        right: 0;
        height: 21px;
        width: 21px;
        background-color: white;
        display: block;
        border: 2px solid rgba(132, 146, 166, 0.5);
        border-radius:50%;
        &::after {
            content: '';
            position: absolute;
            opacity: 0;
            left: 9px;
            top: -4px;
            width: 7px;
            height: 16px;
            border:2px solid ${props=>props.theme.colors.mint};
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            transition: ${(props) => props.theme.transitions.default};
        }
        &::before {
            content: '';
            position: absolute;
            opacity: 0;
            left: 11px;
            top: -6px;
            width: 7px;
            height: 22px;
            border: 2px solid white;
            border-width: 0 8px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transition: all 0.25s ease;
            -webkit-transition: all 0.25s ease;
            transition: all 0.25s ease;
        }
    }

    &::hover input ~ .checkmark {
        background-color: #ccc;
    }
    
    input:checked ~ .checkmark::after {
        opacity: 1;
    }
    input:checked ~ .checkmark::before {
        opacity: 1;
    }
    input:checked ~ .checkmark {
        border: 2px solid ${props=>props.theme.colors.mint};
    }
`;