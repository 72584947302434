import React, { useState } from "react";
import styled from "styled-components/macro";
import { Link } from "@reach/router";

export const GuessMenuLink = styled((props) => {
  const [active, setActive] = useState(false);
  const isActive = ({ isPartiallyCurrent }) => {
    setActive(false);
    if (isPartiallyCurrent) {
      setActive(true);
      return {
        style: {
          backgroundColor: "rgba(0, 69, 245, 0.2)",
          color: "#0045F5",
          img: {
            border: "1px solid #0045F5",
          },
        },
      };
    }
  };

  return (
    <>
      {props.text ? (
        <span
          className={`${props.className} ${active ? "active-link" : ""}`}
          onClick={props.onClick}
        >
          {props.children}
        </span>
      ) : (
        <Link
          getProps={isActive}
          to={props.to}
          className={`${props.className} ${active ? "active-link" : ""}`}
        >
          {props.children}
        </Link>
      )}
    </>
  );
})`
  display: block;
  display: flex;
  color: ${(props) => props.theme.colors.navy};
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: ${(props) => (props.height ? props.height : "104px")};
  padding: 24px 16px;
  margin-top: -1px;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 69, 245, 0.2);
    color: ${(props) => props.theme.colors.royalBlue};
    svg {
      color: ${(props) => props.theme.colors.royalBlue};
      rect {
        stroke: ${(props) => props.theme.colors.royalBlue};
      }
    }
    img {
      border: 1px solid ${(props) => props.theme.colors.royalBlue};
    }
    ${(props) =>
      props.admin &&
      `
            background-color: ${props.theme.colors.royalBlue};
        `}
  }

  img {
    margin-right: 16px;
    border-radius: 50%;
    border: 1px solid
      ${(props) =>
        props.active ? props.theme.colors.royalBlue : props.theme.colors.navy};
    object-fit: contain;
    background-color: white;
    padding: 0;
  }
  svg {
    margin-right: 16px;
  }
`;
