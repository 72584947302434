import { Spinner } from "@chakra-ui/core";
import React from "react";
import styled from "styled-components/macro";

import { Button } from "../../Button";

const ButtonStyled = styled(Button)`
  align-self: flex-start;
  position: relative;
  height: 60px;
  font-size: ${(props) => props.fontSize} !important;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  min-width: ${(props) => (props.refineResults ? "150px" : "200px")};
  padding: ${(props) => (props.refineResults ? "14px 27px" : "17px 27px")};
  width: ${(props) => (props.widthExpand ? "100%" : "auto")};
`;

export const Submit = ({
  type,
  text,
  isSubmitting,
  marginTop,
  marginBottom,
  refineResults,
  fontSize,
  spinner,
  disabled,
  ...props
}) => {
  return (
    <ButtonStyled
      marginTop={marginTop}
      marginBottom={marginBottom}
      fontSize={fontSize}
      type={type || "submit"}
      disabled={isSubmitting || disabled}
      disablePointer={isSubmitting || disabled}
      disabledStyle={isSubmitting || disabled}
      refineResults={refineResults}
      {...props}
    >
      {isSubmitting ? (
        spinner ? (
          <Spinner />
        ) : (
          props.submittingText || "Submitting..."
        )
      ) : text ? (
        text
      ) : (
        "Submit"
      )}
    </ButtonStyled>
  );
};
