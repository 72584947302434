import React from "react";
import styled from "styled-components/macro";

const StyledTooltip = styled.div`
  background-color: ${(props) => props.theme.colors.royalBlue};
  padding: ${(props) => props.theme.space.xs};
  border-radius: 4px;

  > div {
    text-transform: uppercase;
    color: white;
    font-size: ${(props) => props.theme.fontSizes.sm};
    font-weight: 700;
    margin-bottom: ${(props) => props.theme.space.xs};

    &:last-of-type {
      margin-bottom: 0;
    }

    span {
      display: block;
      margin-bottom: 4px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

export const UserTooltip = ({
  children,
  industryExperience,
  projectsCompleted,
  ...props
}) => {
  return (
    <StyledTooltip>
      <div>
        <span>Industry experience</span>
        <span>
          {industryExperience} year{industryExperience > 1 && "s"}
        </span>
      </div>

      <div>
        <span>Projects completed in this sector</span>
        <span>{projectsCompleted}</span>
      </div>
    </StyledTooltip>
  );
};
