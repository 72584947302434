import React from 'react';
import styled from 'styled-components/macro';
import {ReactComponent as Warning} from '../../../icons/warning.svg';

export const FlashWrapper = styled.div`
    padding:25px;
    background-color:${props=>props.theme.colors.danger};
    border-radius: 5px;
    min-width:500px;
    transition: 0.15s all ease;
    margin:${props=>props.margin? props.margin : '0px'};
    h3{
        color:${props=>props.theme.colors.snowDark} !important;
        display:flex;
        align-items:center;
        line-height: 110%;
        margin:0;
        span{
            margin-left:10px;
        }
    }
    p{
        margin-top:8px;
        font-size: 14px;
        line-height: 20px;
        color:${props=>props.theme.colors.snowDark};
        margin-bottom:0;
        margin-left:34px;
        a{
            color:${props=>props.theme.colors.snowDark};
            text-decoration: underline;
            font-weight: bold;
        }
    }

`;

export const FlashError = ({ heading, text, margin }) => {
    return (
        <FlashWrapper margin={margin}>
            <h3> <Warning /> <span>{heading}</span></h3>
            <p>{text}</p>
        </FlashWrapper>
    );
};
