import React from "react";
import { connect } from "react-redux";
import Loader from "react-spinners/BeatLoader";

import {
  capitalise,
  shortenDigits,
  getSlicedDocuments,
} from "../../utils/helpers";

import {
  CompanyResultCardStyled,
  Buttons,
  Header,
  Background,
  HeaderOverlay,
  HeaderControls,
  CompanyName,
  Logo,
} from "./styled";
import { ControlButton } from "./ControlButton";
import { DataRow } from "../CompanyResultCardTable/DataRow";
import { Button } from "../Button";
import { ProjectGroup } from "../ProjectGroup";
import { AvatarGroup } from "../AvatarGroup";
import { DocumentItem } from "../DocumentItem";
import {
  removeFromList,
  addToList,
  handleLockClick,
} from "actions/searchActions";
import { FeatureProject } from "../FeatureProject/FeatureProject";
import { MatchesLabel, TooltipLabel } from "components/MatchesLabel";

const CompanyResultCardConnected = ({
  company,
  handleLockClick,
  isShortlist,
  unlockSearchItem,
  locked,
  compareCard,
  groupId,
  compared,
  shortlisted,
  isResults,
  compareSlideoutClick,
  cardType,
  removeFromList,
  addToList,
  addingCompareCard,
  addingShortlistCard,
  sectorId,
  profileType,
  sticked,
  sLeft,
  current_company,
  criteria_search,
  disciplines,
  trades,
  specialities,
  ...props
}) => {
  const compareButtonText = () => {
    if (addingCompareCard[company.id]) {
      return <Loader size={8} margin={1} color="#0045F5" />;
    } else {
      return compared ? "Compared" : "Compare";
    }
  };

  const shortlistButtonText = () => {
    if (addingShortlistCard[company.id]) {
      return <Loader size={8} margin={1} color="#0045F5" />;
    } else {
      return shortlisted ? "Shortlisted" : "Shortlist";
    }
  };
  const quarters = ["Q1", "Q2", "Q3", "Q4"];
  const formatDate = (date) => {
    let d = new Date(date);
    return `${quarters[(d.getMonth() / 3) | 0]} ${d.getFullYear()}`;
  };
  const renderLGAS = (lgas) => {
    return lgas.slice(0, 5).map((lga) => {
      return (
        <>
          {lga} <br />
        </>
      );
    });
  };
  const findIndex = (id, array) => {
    if (array.length > 0) {
      if ("id" in array[0]) {
        return array.findIndex((arr) => arr.id === id);
      } else {
        return array.findIndex((arr) => arr.value === id);
      }
    }
  };
  const checkingCriteria = (type) => {
    if (type === 2 && criteria_search.specialities !== null) {
      return criteria_search.specialities.map((id) => {
        return specialities[findIndex(id, specialities)]?.label;
      });
    }
    if (type === 3 && criteria_search.trades !== null) {
      return criteria_search.trades.map((id) => {
        return trades[findIndex(id, trades)]?.label;
      });
    }
    if (type === 4 && criteria_search.discipline !== null) {
      return criteria_search.discipline.map((id) => {
        return disciplines[findIndex(id, disciplines)]?.value;
      });
    }
  };

  const checkMatches = (label, type) => {
    let index;
    if (type === 2 && company.speciality?.length > 0) {
      index = company.speciality.findIndex((special) => label === special.name);
      if (index >= 0) {
        return true;
      }
      return false;
    }
    if (type === 3 && company.trades?.length > 0) {
      index = company.trades.findIndex((trad) => label === trad.name);
      if (index >= 0) {
        return true;
      }
      return false;
    }
    if (type === 4 && company.disciplines?.length > 0) {
      index = company.disciplines.findIndex(
        (disciple) => label === disciple.name
      );
      if (index >= 0) {
        return true;
      }
      return false;
    }
  };

  const matchedCount = (type) => {
    let totalMatched = [];
    if (type === 2 && company.speciality?.length > 0) {
      criteria_search.specialities.forEach((id) => {
        company.speciality.forEach((special) => {
          if (special.id === id) {
            totalMatched.push(special);
          }
        });
      });
    }
    if (type === 3 && company.trades?.length > 0) {
      criteria_search.trades.forEach((id) => {
        company.trades.forEach((trade) => {
          if (trade.id === id) {
            totalMatched.push(trade);
          }
        });
      });
    }
    if (type === 4 && company.disciplines?.length > 0) {
      criteria_search.discipline.forEach((id) => {
        company.disciplines.forEach((disciple) => {
          if (disciple.id === id) {
            totalMatched.push(disciple);
          }
        });
      });
    }
    return totalMatched;
  };
  const loadHandle = (obj) => {
    let checkLoaded = Object.values(obj);
    return checkLoaded.includes(true);
  };

  const getAnnualTurnover = () => {
    const financialDocs = company.documentsFormatted.find(
      (doc) => doc.category_id === 3
    );
    let sum = 0;
    for (let i = 0; i < financialDocs?.documents.length; i++) {
      sum += financialDocs.documents[i].extra_info.annual_turnover
        ? parseInt(financialDocs.documents[i].extra_info.annual_turnover)
        : 0;
    }
    if (sum > 0) return shortenDigits(sum);
    else return <>&mdash;</>;
  };
  const isDocumentExistWithSubCategory = (docs, subCat) => {
    return docs.find((x) => x.name === subCat);
  };
  const getHeight = (c_id) => {
    if ([4, 5, 6].includes(c_id) && compareCard) return "auto";
    return "85px";
  };
  const getSubCategories = (subCats, docs) => {
    return subCats.map((subCategory, index) => {
      const document = isDocumentExistWithSubCategory(docs, subCategory.name);
      return (
        <DocumentItem
          style={{ marginBottom: "0.65rem" }}
          color="grayLight"
          document={document ? document : subCategory}
          title={`${subCategory.name}`}
          key={index}
          small
          showIndicator
          iconIndicator={
            document
              ? document.display === "public" ||
                document?.approved_companies?.includes(current_company?.id)
              : false
          }
          check={document}
          cross={!document}
          current_company={current_company}
        />
      );
    });
  };

  return (
    <CompanyResultCardStyled compareCard={compareCard}>
      <Header>
        <Background src={company.banner_path} />

        <HeaderOverlay>
          <HeaderControls>
            <div>
              <ControlButton
                as="a"
                href={`/company/${company.id}`}
                target="_blank"
                type="view"
              />
            </div>

            <div>
              <ControlButton
                type={locked ? "unlock" : "lock"}
                onClick={() =>
                  handleLockClick(company, cardType, locked ? "remove" : "add")
                }
              />

              <ControlButton
                type="remove"
                onClick={() => removeFromList(company.id, cardType, groupId)}
              />
            </div>
          </HeaderControls>
          {company.logo_path && <Logo src={company.logo_path} />}
        </HeaderOverlay>
      </Header>

      <Buttons>
        {!isShortlist && !compareCard && (
          <Button
            size="xs"
            btntype={compared ? "primary" : "secondary"}
            alt={compared}
            disablePointer={compared}
            onClick={() => {
              addToList(company, "compare", groupId);
            }}
            disabled={loadHandle(addingCompareCard)}
          >
            {compareButtonText()}
          </Button>
        )}

        <Button
          size="xs"
          disablePointer={shortlisted}
          onClick={() => addToList(company, "shortlist", groupId)}
          alt={shortlisted}
          disabled={loadHandle(addingShortlistCard)}
        >
          {shortlistButtonText()}
        </Button>
      </Buttons>

      <div
        className="comp-name"
        style={{ height: compareCard ? "90px" : "95px" }}
      >
        <DataRow
          sticky={sticked}
          sLeft={sLeft}
          noBorder
          contentType={compareCard ? "company-name-location" : "company-name"}
        >
          <CompanyName className="company-name">{company.name}</CompanyName>
          <span className="updated_at">
            profile updated {formatDate(company.updated_at)}
          </span>
        </DataRow>
      </div>

      <DataRow noBorder={compareCard} contentType="suburb-city">
        {company.primary_address?.city ? (
          company.primary_address.city
        ) : (
          <>&mdash;</>
        )}
      </DataRow>
      <DataRow contentType="lga" noBorder={compareCard}>
        {company.lgas && company.lgas.length > 0 ? (
          renderLGAS(company.lgas)
        ) : (
          <>&mdash;</>
        )}
      </DataRow>

      <DataRow noBorder={compareCard} contentType="company-size">
        {company.size ? capitalise(company.size) : <>&mdash;</>}
      </DataRow>

      {profileType !== 1 && profileType !== 4 && profileType !== 3 && (
        <DataRow noBorder={compareCard} contentType="anual-turnover">
          {getAnnualTurnover()}
        </DataRow>
      )}

      {profileType === 3 && (
        <DataRow
          contentType="sector"
          id={"matchesRow2"}
          compareTable={compareCard}
        >
          <div className="matchesContainer">
            {cardType === "compare" ? (
              <p className="countLabel">{matchedCount(2).length} matches</p>
            ) : (
              <TooltipLabel allMatch={matchedCount(2)} id={company.id}>
                <p className="countLabel">{matchedCount(2).length} matches</p>
              </TooltipLabel>
            )}
          </div>

          {checkingCriteria(2).length > 0 &&
            cardType === "compare" &&
            checkingCriteria(2).map((label) => {
              return (
                <MatchesLabel label={label} matched={checkMatches(label, 2)} />
              );
            })}
        </DataRow>
      )}

      <DataRow
        contentType="sector"
        id={"matchesRow"}
        compareTable={compareCard}
      >
        <div className="matchesContainer">
          {cardType === "compare" ? (
            <p className="countLabel">
              {matchedCount(profileType).length} matches
            </p>
          ) : (
            <TooltipLabel allMatch={matchedCount(profileType)} id={company.id}>
              <p className="countLabel">
                {matchedCount(profileType).length} matches
              </p>
            </TooltipLabel>
          )}
        </div>

        {checkingCriteria(profileType).length > 0 &&
          cardType === "compare" &&
          checkingCriteria(profileType).map((label) => {
            return (
              <MatchesLabel
                label={label}
                matched={checkMatches(label, profileType)}
              />
            );
          })}
      </DataRow>

      {profileType !== 3 && (
        <DataRow contentType={compareCard ? "avatar-list" : "avatar-group"}>
          <AvatarGroup
            items={company.key_personnels}
            showButton={compareCard}
            compareSlideoutClick={compareSlideoutClick}
            companyId={company.id}
            list={compareCard}
            sectorId={sectorId}
          />
        </DataRow>
      )}

      {compareCard && (
        <DataRow contentType="project-list">
          <ProjectGroup
            projects={company.compare_projects}
            compare
            compareSlideoutClick={compareSlideoutClick}
            groupId={groupId}
            companyId={company.id}
            imageGrid
            profileType={profileType}
            pastProjects={current_company.pas}
          />
        </DataRow>
      )}

      {!compareCard && (
        <DataRow contentType="project-images">
          {company.past_projects_in_sector ? (
            <FeatureProject
              title={company.projects_feature_name}
              image={company.projects_feature_image}
              count={company.past_projects_in_sector}
            />
          ) : (
            <p>No projects found.</p>
          )}
        </DataRow>
      )}

      {/* {compareCard ? ( */}
      <DataRow contentType="documents">
        {company.documentsFormatted &&
          company.documentsFormatted.length > 0 &&
          company.documentsFormatted.map((category, index) => (
            <div className="document-div" key={index}>
              <h4 style={{ margin: "0 0 10px 0" }}>{category.category_name}</h4>
              <div
                className="documents"
                style={{ height: getHeight(category.category_id) }}
              >
                {category.subcategories.length && compareCard ? (
                  getSubCategories(category.subcategories, category.documents)
                ) : category.documents.length ? (
                  getSlicedDocuments(
                    category.documents,
                    category.category_id,
                    compareCard
                  ).map((document, index) => {
                    return (
                      <DocumentItem
                        style={{ marginBottom: "0.65rem" }}
                        color="grayLight"
                        document={document}
                        title={`${document.name} ${
                          document.display === "private" ? "(Private)" : ""
                        }`}
                        key={index}
                        small
                        showIndicator
                        iconIndicator={
                          document.display === "public" ||
                          document?.approved_companies?.includes(
                            current_company?.id
                          )
                        }
                        current_company={current_company}
                      />
                    );
                  })
                ) : (
                  <div style={{ marginBottom: "0.55rem" }}>&mdash;</div>
                )}
                {category.documents &&
                category.documents.length > 2 &&
                !compareCard ? (
                  <div className="extra-docs">
                    +{category.documents.length - 2}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
      </DataRow>

      <Button
        as="a"
        target="_blank"
        href={`/company/${company.id}`}
        size="xs"
        style={{ textAlign: "center" }}
        mt="auto"
      >
        View Profile
      </Button>
    </CompanyResultCardStyled>
  );
};

CompanyResultCardConnected.defaultProps = {
  company: { location: {}, disciplines: [] },
};

const mapStateToProps = (state, ownProps) => {
  return {
    addingCompareCard: state.search.addingCompareCard,
    addingShortlistCard: state.search.addingShortlistCard,
    groupId: state.search.groupId,
    compared: state.search.compareList
      ? state.search.compareList.some(
          (compareItem) => compareItem && compareItem.id === ownProps.company.id
        )
      : [],
    shortlisted: state.search.shortlist
      ? state.search.shortlist.some(
          (shortlistItem) => shortlistItem.id === ownProps.company.id
        )
      : [],
    sectorId: state.search.searchCriteria.sector_id,
    profileType: state.search.searchCriteria.profile_type,
    current_company: state.auth.current_company,
    criteria_search: state.search.searchCriteria,
    disciplines: state.search.disciplines,
    trades: state.companyProfile.trades,
    specialities: state.companyProfile.specialities,
  };
};

export const CompanyResultCard = connect(mapStateToProps, {
  removeFromList,
  addToList,
  handleLockClick,
})(CompanyResultCardConnected);
