import React from 'react';
import styled from 'styled-components/macro';

export const SortIndicatorStyled = styled.svg`
    position: relative;
    top: 1px;
    margin-left: ${(props) => props.theme.space.xxs};
`;

export const SortIndicator = ({ sortKey, currentSortKey, currentSortOrder, ...props }) => {
    return (
        <SortIndicatorStyled fill='none' height='12' viewBox='0 0 6 12' width='6' xmlns='http://www.w3.org/2000/svg'>
            <g fill='#8492a6'>
                <path
                    opacity={sortKey === currentSortKey && currentSortOrder === 'asc' ? '1' : '0.35'}
                    d='m2.56699.75c.19245-.333333.67357-.333333.86602 0l1.73205 3c.19245.33333-.04811.75-.43301.75h-3.4641c-.384901 0-.625463-.41667-.433013-.75z'
                />
                <path
                    d='m3.43301 11.25c-.19245.3333-.67357.3333-.86602 0l-1.732053-3c-.19245-.33333.048113-.75.433013-.75h3.4641c.3849 0 .62546.41667.43301.75z'
                    opacity={sortKey === currentSortKey && currentSortOrder === 'desc' ? '1' : '0.35'}
                />
            </g>
        </SortIndicatorStyled>
    );
};
