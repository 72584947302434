import React from "react";
import Select from "react-select";
import { useField, useFormikContext } from "formik";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import { ReactComponent as InfoIcon } from "../../../icons/info.svg";
import { FormLabel } from "../TextInput";
import ReactTooltip from "react-tooltip";
import { Error } from "../Error";
import { theme } from "../../../theme";

const animatedComponents = makeAnimated();

export const MultiSelect = ({
  options,
  name,
  defaultValue,
  capitalize,
  value,
  freeText,
  guideLine,
  guideLineText,
  label,
  required,
  errorLeftAlign,
  sortingValue,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const isError = meta.touched && meta.error;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      color: state.isSelected ? "#ffffff" : "#5B6B88",
    }),

    control: (provided) => ({
      ...provided,
      padding: "0.338rem",
      minHeight: "60px",
      borderColor: isError ? "#E11900" : "#e7eaee",
      marginBottom: isError ? "2.15rem" : "1.75rem",
    }),

    menu: (provided, state) => ({
      ...provided,
      zIndex: "100",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#336AF7",
    }),

    multiValueLabel: (provided, state) => ({
      ...provided,
      textTransform: state.selectProps.capitalize ? "capitalize" : "none",
    }),

    multiValueRemove: (provided, state) => ({
      ...provided,
      color: theme.colors.grayDark,
    }),
  };

  const handleOnChange = (values) => {
    //create array of values for formik
    const valuesArray = values ? values.map((value) => value.value) : [];
    const sortArray = valuesArray.sort((a,b)=>a-b)
    setFieldValue(name, props.sortingValue ? sortArray : valuesArray);
  };

  const SelectComponent = props.creatable ? CreatableSelect : Select;

  if (options && field && Array.isArray(field.value)) {
    value = field.value.map((id) => {
      return options.find((option) => {
        return option.value === id;
      });
    });
  }

  return (
    <div className={props.className}>
      {label && (
        <FormLabel>
          {label}
          {required && <span>*</span>}
          {guideLine && (
            <a
              style={{ marginLeft: "5px" }}
              target="_blank"
              rel="noopener noreferrer"
              href={guideLine}
            >
              <InfoIcon data-tip data-for={name} />
            </a>
          )}
          {guideLineText &&  <InfoIcon data-tip data-for={name} />}
          {guideLineText &&
          <ReactTooltip
            className="guide"
            id={name}
            place="top"
            arrowColor="transparent"
          >
            {guideLineText}
        </ReactTooltip>
          }
        </FormLabel>
      )}

      <SelectComponent
        isMulti
        styles={customStyles}
        closeMenuOnSelect={false}
        components={{
          animatedComponents,
          IndicatorSeparator: () => null,
          // DropdownIndicator: () => null
        }}
        options={
          freeText && options
            ? options.map((option) => ({ value: option, label: option }))
            : options
        }
        onChange={handleOnChange}
        //if using a creatable free text input - we need to structure the data properly for react-select e.g {value: '', label: ''}
        value={
          freeText && value
            ? field.value.map((text) => ({ value: text, label: text }))
            : value
        }
        capitalize={capitalize}
        noOptionsMessage={() => null}
      />
      <Error meta={meta} />
    </div>
  );
};
