import styled from 'styled-components/macro';

export const FormPageStyled = styled.div`
    background-color: ${(props) => props.theme.colors.blueLight};
    color: white;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: auto;
`;
