import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { EditUserProfileStyled } from "./styled";
import { update_user } from "../../actions/AccountManagementActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { TabHeader } from "../TabHeader";
import { Select } from "../FormInputs/Select";
import { SocialInvitation } from "../SocialInvitation/SocialInvitation";
import { userRoles, findUserRole } from "../../utils/helpers";
import { Divider } from "../Divider";
import { getPasswordStrength } from "../../utils/helpers";
import { PasswordValidator } from "../FormInputs/PasswordValidator";
import { FlashError } from "../FormInputs/FlashError";

export const EditUserProfileComponent = ({
  company,
  viewOnly,
  current_user,
  ...props
}) => {
  const [disableField, setDisableField] = useState(true);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [passwordRules, setPasswordRules] = useState({
    letter: false,
    capital: false,
    number: false,
    count: false,
  });

  const initialValues = {
    id: current_user.id,
    first_name: current_user.first_name || "",
    last_name: current_user.last_name || "",
    phone: current_user.phone || "",
    email: current_user.email || "",
    position: current_user.position || "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("* THIS FIELD IS REQUIRED"),
    last_name: Yup.string().required("* THIS FIELD IS REQUIRED"),
    email: Yup.string().required("* THIS FIELD IS REQUIRED"),
    phone: Yup.string()
      .required("* THIS FIELD IS REQUIRED")
      .matches(
        /^(?=.*)((?:\+?61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?([0-1][2-47-8])\)?) ?-?(?=.*)((\d{1} ?-?\d{3}$)|(00 ?-?\d{4} ?-?\d{4}$)|( ?-?\d{4} ?-?\d{4}$)|(\d{2} ?-?\d{3} ?-?\d{3}$))/,
        "Invalid Phone No."
      ),
    position: Yup.string().required("* THIS FIELD IS REQUIRED"),
    password: !disableField
      ? Yup.string()
          .required("* This Field is required")
          .test(
            "strong password",
            "*The supplied password does not meet the requirements",
            (value) => {
              let validRules = passwordRules;
              if (value) {
                validRules = getPasswordStrength(value, passwordRules);
                setPasswordRules(validRules);
                return Object.values(validRules).every((val) => val === true);
              } else {
                validRules.letter = false;
                validRules.number = false;
                validRules.capital = false;
                validRules.count = false;
                setPasswordRules(validRules);
                return false;
              }
            }
          )
      : "",
  });

  return company !== undefined ? (
    <EditUserProfileStyled>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors }) => {
          try {
            await props.update_user(values, true);
            setDisableField(true);
          } catch (e) {
            const error = await e.response.json();
            toast.error(error.message);
            error.errors !== undefined && setErrors(error.errors);
          }
        }}
      >
        {(props) => {
          const { isSubmitting, values, status, setFieldValue, setStatus } =
            props;
          const onSubmitValidationError = (e) => {
            setStatus("Please review errors in form");
          };
          return (
            <>
              <TabHeader heading="My Profile" />
              <Form
                onChange={() => {
                  setEnableSubmit(true);
                }}
              >
                <div className="row">
                  <TextInput
                    label="First Name"
                    required={!viewOnly}
                    name="first_name"
                    className="col xl-6"
                    disabled={viewOnly}
                  />
                  <TextInput
                    label="Last Name"
                    required={!viewOnly}
                    name="last_name"
                    className="col xl-6"
                    disabled={viewOnly}
                  />
                  <TextInput
                    label="Phone Number"
                    required={!viewOnly}
                    name="phone"
                    className="col xl-6"
                    disabled={viewOnly}
                  />
                  <TextInput
                    label="Email Address"
                    required={!viewOnly}
                    name="email"
                    className="col xl-6"
                    disabled
                  />
                  <Select
                    required="true"
                    options={userRoles}
                    name={"position"}
                    label={"User Type"}
                    className={"col xl-6"}
                    value={findUserRole(values.position)}
                    disabled
                  />
                  <TextInput
                    data-tip
                    data-for="pass_validation"
                    label="Password"
                    placeholder={"********"}
                    name="password"
                    type={"password"}
                    className="col xl-6"
                    disabled={disableField || viewOnly}
                    handleToggler={setDisableField}
                    icon
                    disableToggler
                    setFieldValue={setFieldValue}
                  />
                  <PasswordValidator
                    dataId={"pass_validation"}
                    passwordRules={passwordRules}
                  />
                  <div className="col xl-6">
                    {status && (
                      <FlashError
                        heading={"Required Field"}
                        text={
                          "There are incomplete required fields, Please complete them."
                        }
                        margin="20px 0px 0px 0px"
                      />
                    )}
                  </div>
                </div>

                {!viewOnly && (
                  <>
                    <Submit
                      type="submit"
                      isSubmitting={isSubmitting}
                      text="Save Details"
                      marginTop={"24px"}
                      submittingText="Saving..."
                      disabled={!enableSubmit}
                    />
                    <div className="social-invitations">
                      <SocialInvitation />
                    </div>
                  </>
                )}

                <OnSubmitValidationError callback={onSubmitValidationError} />
              </Form>
              <Divider top="40px" />
            </>
          );
        }}
      </Formik>
    </EditUserProfileStyled>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const current_user = state.auth.user;

  return {
    isRequesting: state.companyProfile.isRequesting,
    company: company,
    current_user: current_user,
  };
};

export const EditUserProfile = connect(mapStateToProps, { update_user })(
  EditUserProfileComponent
);
