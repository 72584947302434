import styled from 'styled-components/macro';

export const IconBoxStyled = styled.span`
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 8px;
    border-radius: ${(props) => props.theme.radii.default};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${(props) => props.theme.transitions.default};
    color: ${(props) => (props.color === 'dark' ? props.theme.colors.gray : 'white')};

    &:hover {
        color: ${(props) => (props.color === 'dark' ? props.theme.colors.gray : 'white')};
        ${(props) =>
            props.admin &&
            `
            background-color: ${props.theme.colors.royalBlue};
        `}
    }

    ${(props) =>
        props.admin &&
        `
        background-color: ${props.theme.colors.royalBlueLight};
    `};


    svg {
        width: 32px;
        height: 32px;
        color:#263859;

        path {
            stroke: currentColor;
        }
    }

    ${props=>props.logo && `
        border-radius: 8px;
        padding:0px;
        margin-top: 14px;
    `}
       
`;

export const IconStyled = styled.span`
    display: flex;
    align-items: center;

    ${(props) =>
        props.color === 'dark' &&
        `
    padding: 7px;
    border-radius: ${props.theme.radii.default};
    `};
`;
