import styled, { css } from "styled-components/macro";

const sectionListingStyles = css`
  .first-item {
    padding-left: 1rem;
  }

  .icon-email {
    display: flex;
    height: 100%;
  }
  .header-cell {
    display: flex;
    align-items: center;
  }
`;

export const CustomTable = styled.div`
  ${sectionListingStyles};
  padding: 0 1rem;
  border: solid #dfdfdf 2px;
  border-radius: 0.5rem;
`;

export const ContentRowStyled = styled.div`
  padding: 1.25rem;
  border-bottom: solid #dfdfdf 1px;
  line-height: 2.5rem;
  align-items: center;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1rem;
`;

export const DotPage = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  span {
    width: 8px;
    height: 8px;
    background: #c9c9c9;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      background: grey;
    }
  }
  .active {
    background: #003acd !important;
  }
`;
