import React, { useState } from "react";
import styled from "styled-components/macro";
import Skeleton from "react-loading-skeleton";
import api from "api";

import { DataTable } from "../../../components/DataTable";
import { ResourceLink } from "../../../components/ResourceLink";
import { Toolbar } from "../../../components/Toolbar";
import { ButtonLink } from "../../../components/ButtonLink";
import { NoResults } from "../../../components/NoResults";
import { SearchBox } from "../../../components/SearchBox";
import { TabHeader } from "../../../components/TabHeader";
import { TabContent } from "../../../components/Tabs/styled";

export const ProjectsRow = styled.div`
  display: flex;
`;

export const Resources = ({
  resources,
  companyId,
  isRequesting,
  search,
  adminControls,
  ...props
}) => {
  const [searchResults, setSearchResults] = useState([...resources]);
  const [hasSearched, setHasSearched] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const tableHeaders = [
    "",
    { label: "Name", key: "first_name" },
    { label: "Position", key: "position" },
    { label: "Industry Experience", key: "experience" },
    { label: "Top 3 Sectors", key: "sectors[0].name" },
    "",
  ];

  const onFilterSubmit = async (values) => {
    const { search, sector } = values;
    setHasSearched(true);
    setIsSearching(true);

    try {
      const filteredProjects = await api
        .get(
          `companies/${companyId}/key-personnel?filter[company_id]=${companyId}&search=${search}&filter[sector_ids]=${sector}`
        )
        .json();
      setSearchResults([...filteredProjects.data]);
    } catch (e) {
      console.log(e);
    }

    setIsSearching(false);
  };

  return (
    <>
      <TabHeader heading="Key Personnel & Expertise" />
      <TabContent>
        <Toolbar>
          {adminControls && <ButtonLink to="new">Add Key Personnel</ButtonLink>}

          {search && (
            <SearchBox
              placeholder="Search resources"
              handleFormSubmit={onFilterSubmit}
              searchInput
              sectorFilter
              multiSector
            />
          )}
        </Toolbar>

        {/* todo: clean this logic up - need to separate out component into container - use initial value with useState properly */}
        {!isRequesting && !isSearching ? (
          hasSearched && !searchResults.length ? (
            <NoResults />
          ) : resources.length ? (
            <DataTable
              headers={tableHeaders}
              data={hasSearched ? searchResults : resources}
              component={ResourceLink}
              componentProps={{
                className: "col sm-12",
                edit: adminControls ? true : false,
              }}
            />
          ) : (
            <NoResults />
          )
        ) : (
          <Skeleton count={5} duration={0.5} />
        )}
      </TabContent>
    </>
  );
};

Resources.defaultProps = {
  search: true,
  adminControls: true,
};
