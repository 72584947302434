import React, { useState } from "react";
import { useField } from "formik";
import styled from "styled-components/macro";
import { TextAreaStyled, TextAreaContainer, WordCountStyled } from "./styled";
import { Error } from "../Error";
import { ReactComponent as InfoIcon } from "../../../icons/info.svg";
import ReactTooltip from "react-tooltip";

export const FormLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.grayLight};
  font-weight: 700;
  display: flex;
  margin-bottom: ${(props) => props.theme.space.xxs};
  letter-spacing: 1.125px;
  > span {
    color: #dc2538;
    margin-left: 3px;
  }

  > svg {
    margin-left: 4px;
  }

  .guide {
    border: 0.5px solid #8492a6;
    border-radius: 4px;
    letter-spacing: 0.1px;
    font-family: ${(props) => props.theme.fonts.open_sauce_two};
    font-size: 13px;
    text-transform: none;
    padding: 4px 8px;
    font-weight: normal;
    max-width: 400px;
  }
`;

export const TextArea = ({
  label,
  required,
  name,
  errorBottom,
  characterLimit,
  guideLine,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const [characterCount, setCharacterCount] = useState(
    field.value?.toString().length
  );

  const onChange = (e) => {
    const newVal = e.currentTarget.value;
    helpers.setValue(newVal);
    setCharacterCount(newVal.toString().length);
  };

  const textArea = (
    <TextAreaStyled
      className="textarea "
      value={field.value}
      maxLength={characterLimit}
      onChange={onChange}
      {...props}
    />
  );

  return (
    <div className={props.className}>
      {label && (
        <FormLabel htmlFor={props.id || props.name}>
          {label}
          {required && <span>*</span>}
          {guideLine && <InfoIcon data-tip data-for={name} />}
          <ReactTooltip
            className="guide"
            id={name}
            place="top"
            arrowColor="transparent"
          >
            {guideLine}
          </ReactTooltip>
        </FormLabel>
      )}

      {characterLimit ? (
        <TextAreaContainer isError={meta.error && meta.touched}>
          {textArea}
          <WordCountStyled>
            {characterLimit - characterCount} character
            {characterLimit - characterCount > 1 && "s"} remaining
          </WordCountStyled>
        </TextAreaContainer>
      ) : (
        textArea
      )}

      <Error errorBottom={errorBottom} meta={meta} />
    </div>
  );
};
