import styled, { css } from "styled-components/macro";

const sectionListingStyles = css`
  h2 {
    font-size: 18px;
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
  }
  .section-header {
    padding: 1.25rem;
    border-bottom: solid #dfdfdf 1px;
  }
  .first-item {
    padding-left: 1rem;
  }
  span {
    font-size: ${(props) => props.theme.fontSizes.sm};
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.125px;
  }
  .create-btn {
    margin-bottom: 2rem;
  }
  .last-row {
    border: none !important;
  }
  .last {
    display: flex;
    justify-content: end;
  }
  .no-border {
    display: flex;
    align-items: center;
  }
`;

export const CustomTable = styled.div`
  padding: 0 1rem;
  border: solid #dfdfdf 2px;
  border-radius: 0.5rem;
`;

export const AccountUsersStyled = styled.div`
  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #253858;
  }
`;

export const UsersSection = styled.div`
  ${sectionListingStyles};
  .users-note {
    display: flex;
    column-gap: 15%;
    p {
      color: ${(props) => props.theme.colors.black};
      font-weight: bold;
    }
  }
`;

export const InfoRow = styled.div`
  padding: 1.25rem;
  border-bottom: solid #dfdfdf 1px;
  line-height: 2.5rem;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 2rem;
`;

export const DotPage = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  span {
    width: 8px;
    height: 8px;
    background: #c9c9c9;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      background: grey;
    }
  }
  .active {
    background: #003acd !important;
  }
`;
