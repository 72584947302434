import React from "react";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import { useField, useFormikContext } from "formik";

import { FormLabel } from "../TextInput";
import { Error } from "../Error";
import { theme } from "../../../theme";
import { ReactComponent as InfoIcon } from "../../../icons/info.svg";
import { ReactComponent as CrossIcon } from "../../../icons/cross.svg";
import ReactTooltip from "react-tooltip";

const animatedComponents = makeAnimated();

export const Select = ({
  options,
  placeholder,
  name,
  label,
  required,
  defaultValue,
  noLabel,
  value,
  className,
  error,
  freeText,
  customOnChange,
  disabled,
  height,
  width,
  errorBottom,
  handleOnMenuOpen,
  errorText,
  adjacentError,
  guideLine,
  clearAble,
  ...props
}) => {
  //eslint-disable-next-line
  const [field, meta] = useField(name);
  const isError = (meta && meta.error && meta.touched) || errorText || error;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      color: state.isSelected ? "#ffffff" : theme.colors.gray,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#336AF7",
    }),
    control: (provided) => ({
      ...provided,
      padding: "0.338rem",
      minHeight: 0,
      borderColor: isError ? theme.colors.danger : "#e7eaee",
      marginBottom: isError ? "2.1rem" : "1.75rem",
      color: "green",
      height: height || "60px",
      width: width,
      backgroundColor: disabled ? "#d7d7d7" : "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colors.gray,
    }),
  };

  const handleOnChange = (option) => {
    setFieldValue(name, option ? option.value : "");
  };

  if (!value && freeText) {
    value = { label: field.value, value: field.value };
  }

  return (
    
    <div
      className={className}
      style={{
        position: "relative",
        paddingBottom: isError ? "2px" : adjacentError ? "24px" : "0px", //fixed styling
      }}
    >
      
      {label && !noLabel && (
        <FormLabel>
          {label}
          {required && <span>*</span>}
          {guideLine && <InfoIcon data-tip data-for={name} />}
          <ReactTooltip
            className="guide"
            id={name}
            place="top"
            arrowColor="transparent"
          >
            {guideLine}
          </ReactTooltip>
        </FormLabel>
      )}

      <ReactSelect
        name={name}
        styles={customStyles}
        closeMenuOnSelect={true}
        components={{ animatedComponents, IndicatorSeparator: () => null }}
        onChange={customOnChange ? customOnChange : handleOnChange}
        value={value}
        onBlur={() => setFieldTouched(name, true)}
        options={
          freeText && options
            ? options.map((option) => ({ value: option, label: option }))
            : options
        }
        defaultValue={defaultValue}
        isDisabled={disabled}
        placeholder={placeholder}
        onMenuOpen={handleOnMenuOpen}
        {...props}
      />
      {clearAble && (
        <CrossIcon
          style={{
            position: "absolute",
            right: "60px",
            width: "10px",
            height: "10px",
            top: "50px",
            cursor: "pointer",
          }}
          onClick={() => handleOnChange(null)}
        />
      )}
      <Error errorBottom={errorBottom} meta={meta} text={errorText} />
    </div>
  );
};
