import styled from 'styled-components/macro';

const getSize = (size) => {
    switch (size) {
        case 'xs':
            return '22px';
        case 'sm':
            return '35px';
        default:
            return '60px';
    }
};

export const ActionButtonStyled = styled.div`
    width: 35px;
    height: 35px;
    color: white;
    ${'' /* background-color: ${props => props.theme.colors.mint}; */}
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(99, 139, 200, 0.15);
    cursor: pointer;
    width: ${(props) => getSize(props.size)};
    height: ${(props) => getSize(props.size)};
    flex-shrink: 0;

    svg {
        ${(props) =>
            props.size === 'xs' &&
            `
            width: 8px;
        height: 8px;
    `}

        ${(props) =>
            props.size === 'sm' &&
            `
        width: 13px;
        height: 13px;
    `}
    }
`;
