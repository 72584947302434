import React, { useEffect, useState } from "react";
import { useField } from "formik";
import styled from "styled-components/macro";
import { Error } from "../Error";
import { ReactComponent as InfoIcon } from "../../../icons/info.svg";
import ReactTooltip from "react-tooltip";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles-input.scss';
import { TextAreaContainer, WordCountStyled } from "../TextArea/styled";

export const FormLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.grayLight};
  font-weight: 700;
  display: flex;
  margin-bottom: ${(props) => props.theme.space.xxs};
  letter-spacing: 1.125px;
  > span {
    color: #dc2538;
    margin-left: 3px;
  }

  > svg {
    margin-left: 4px;
  }

  .guide {
    border: 0.5px solid #8492a6;
    border-radius: 4px;
    letter-spacing: 0.1px;
    font-family: ${(props) => props.theme.fonts.open_sauce_two};
    font-size: 13px;
    text-transform: none;
    padding: 4px 8px;
    font-weight: normal;
    max-width: 400px;
  }
`;

export const betterClearHTMLTags = (strToSanitize) => {
  let myHTML = new DOMParser()
      .parseFromString(strToSanitize, 'text/html');
    return myHTML.body.textContent || '';
}

export const FormatedInput = ({
  label,
  required,
  name,
  errorBottom,
  characterLimit,
  guideLine,
  setErrors,
  onMaxCharacter,
  ...props
}) => {
   

  const [field, meta, helpers] = useField(name);
  const [characterCount, setCharacterCount] = useState(
    betterClearHTMLTags(field.value).length
  );
  const limited = characterLimit - characterCount <= 0
  if(characterLimit - characterCount <= 0){
    setErrors({summary: "Input has reached max character", description : "Input has reached max character"});
  }
  useEffect(()=>{
    if(characterLimit - characterCount <= 0){
      onMaxCharacter()
      }
  },[characterCount,characterLimit,onMaxCharacter])

  const onChange = (e) => {
    const newVal = e;
    const newLength = betterClearHTMLTags(newVal).length;
    helpers.setValue(newVal);
    setCharacterCount(newLength);
    helpers.setError("true")
    if(newLength === 0){
        helpers.setValue("");
    }
  };
  function isValidKey(e)
    {
      if(limited){
        var charCode = e.keyCode || e.which;
        if (charCode === 8 || charCode === 46){
          return true;
        }
        e.preventDefault()
        return false;
        } 
    }

  const textArea = (
    <ReactQuill modules={{ toolbar: ["bold","italic",{ 'list': 'ordered'}, { 'list': 'bullet' }] }} className="textarea" value={field.value} onChange={onChange} onKeyPress={isValidKey} onKeyDown={isValidKey} />
  );

  return (
    <div className={props.className}>
      {label && (
        <FormLabel htmlFor={props.id || props.name}>
          {label}
          {required && <span>*</span>}
          {guideLine && <InfoIcon data-tip data-for={name} />}
          <ReactTooltip
            className="guide"
            id={name}
            place="top"
            arrowColor="transparent"
          >
            {guideLine}
          </ReactTooltip>
        </FormLabel>
      )}

      {characterLimit ? (
        <TextAreaContainer isError={meta.error && meta.touched}>
          {textArea}
          <WordCountStyled>
            {characterLimit - characterCount} character
            {characterLimit - characterCount > 1 && "s"} remaining
          </WordCountStyled>
        </TextAreaContainer>
      ) : (
        textArea
      )}

      <Error errorBottom={errorBottom} meta={meta} />
    </div>
  );
};