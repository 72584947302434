import styled from "styled-components/macro";
import { HeadingStyled } from "../Heading";
import { KeywordStyled } from "../Keyword";
import { ButtonStyled } from "../Button/styled";

export const CompanyImage = styled.div`
  margin-bottom: 30px;
  border-radius: ${(props) => props.theme.radii.default};
  overflow: hidden;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const CompanySummary = styled.p`
  margin-bottom: 30px;
`;

export const CompanySidebarStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 30px;

  ${(props) => props.theme.mediaQueries.xxl} {
    padding: 50px;
  }

  ${HeadingStyled} {
    font-size: 3.25rem;
    margin: 0 0 15px;
    line-height: 0.9;
  }

  h4 {
    color: ${(props) => props.theme.colors.navy};
    text-transform: uppercase;
    margin-top: 0;
  }

  h5 {
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 0.875rem;
    letter-spacing: 1.125px;
    text-transform: uppercase;
    margin-bottom: 0.875rem;
  }

  .section {
    margin-top: 3rem;
    margin-bottom: ${(props) => props.theme.space.sm_2};

    ${KeywordStyled} {
      margin-right: 0.66rem;
    }
  }

  .footer {
    margin-top: auto;

    ${ButtonStyled} {
      width: 100%;
      margin-bottom: 0.625rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
