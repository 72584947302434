import React from 'react';

import { CompanyCardStyled, CompanyCardTitle, RemoveButton } from './styled';
import { CompanyHeaderMedia } from '../CompanyHeaderMedia';

export const CompanyCard = ({ location, handleRemoveButtonClick, linkNewTab, ...props }) => {
    const link = props.admin ? `/account/${props.id}/company-profile/company-information` : `/company/${props.id}`;

    return (
        <CompanyCardStyled {...props}>
            <div className='company-card__inner'>
                <div className='company-card__header'>
                    {handleRemoveButtonClick && (
                        <RemoveButton type='remove' onClick={(e) => handleRemoveButtonClick(e, props.id)}>
                            remove
                        </RemoveButton>
                    )}
                    <CompanyHeaderMedia
                        bg={props.banner ? props.banner : `${process.env.PUBLIC_URL}/img/placeholder-lg.jpg`}
                        logo={props.logo ? props.logo : `${process.env.PUBLIC_URL}/img/placeholder-lg.jpg`}
                    />
                </div>

                <CompanyCardTitle to={link} linkNewTab={linkNewTab} target='_blank'>
                    {props.name}
                </CompanyCardTitle>

                {props.profileType && <p className='company-card__subtitle capitalize'>{props.profileType.name}</p>}
                {location && location.value && <p className='company-card__subtitle'>{location.value}</p>}
            </div>
        </CompanyCardStyled>
    );
};

CompanyCard.defaultProps = {
    location: '',
    linkNewTab: false
};
