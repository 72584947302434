import React from 'react';
import styled from 'styled-components/macro';

const ToolbarStyled = styled.div`
    margin-bottom: ${(props) => props.theme.space.md};
    display: flex;
    justify-content:space-between;
    align-items: flex-start;

    > * {
        flex-shrink: 0;
    }
    p{
        font-size: ${(props) => props.theme.fontSizes.sm};
        font-weight:700;
        color:${(props) => props.theme.colors.royalBlue};
    }
    .request-notification {
        cursor: pointer;
        display:flex;
        align-items: center;

        .numberCircle {
            background: ${(props) => props.theme.colors.mintDark};
            border-radius: 3em;
            -moz-border-radius: 3em;
            -webkit-border-radius: 3em;
            color: #ffffff;
            height: 1.8em;
            width: 1.8em;
            display: inline-block;
            font-family: 'Roboto', Helvetica, Sans-serif;
            font-size: 12px;
            font-weight:700;
            line-height: 2em;
            text-align: center;
            margin-bottom: 30px;
        }
    }
`;

export const Toolbar = (props) => {
    return <ToolbarStyled>{props.children}</ToolbarStyled>;
};
