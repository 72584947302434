import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as CircleIcon } from '../../icons/arrow.svg';

export const SliderButtonStyled = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: ${(props) => (props.direction === 'next' ? 'auto' : '1.5rem')};
    right: ${(props) => (props.direction === 'next' ? '1.5rem' : 'auto')};
    z-index: 5;
    transition: ${(props) => props.theme.transitions.default};
    padding: 0;
    cursor: pointer;
    --tx: 2px;
    --rotate: ${(props) => (props.direction === 'prev' ? '180deg' : 0)};

    &:hover {
        svg {
            --tx: 3px;
        }
    }

    svg {
        color: ${(props) => props.theme.colors.royalBlue};
        width: 10px;
        height: 18px;
        transform-origin: (0, 0);
        transition: ${(props) => props.theme.transitions.default};
        transform: rotate(var(--rotate)) translateX(var(--tx));
    }
`;

export const SliderButton = ({ direction, ...props }) => {
    return (
        <SliderButtonStyled direction={direction} {...props} className='swiper-next'>
            <CircleIcon />
        </SliderButtonStyled>
    );
};
