import styled from 'styled-components/macro';


export const EditUserProfileStyled = styled.div`

    form{
        padding-left: 48px;
        padding-right: 48px;
    }
    h3{
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #253858;
    }

    .social-invitations{
        display: none;
        float: right;
        margin-top: 65px;
        button {
            margin-left:10px;
        }
    }
`