import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import Skeleton from "react-loading-skeleton";

import { ContentContainer } from "../components/Layout";
import { ContextMenu } from "../components/ContextMenu";
import { getCompany, getPastProjects } from "actions/companyActions";

//Routes
import { MyAccount } from "../routes/account/MyAccount";
import { CompanyProfile } from "../routes/account/companyProfile/CompanyProfile";

const AccountPageWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  ${ContentContainer} {
    margin-left: 320px;
  }
`;

export const AccountComponent = ({
  company,
  getCompany,
  getPastProjects,
  getDocuments,
  loading,
  companyId,
  getCompanySizeOptions,
  ...props
}) => {
  useEffect(() => {
    //Set this company ID from the route variable - this allows anyone to access/edit all companies
    //When auth system is implemented, the getCompany action will be dispatched during login action,
    //and this data will be ready in the store
    getCompany(companyId);
    getPastProjects(companyId);
  }, [getCompany, getPastProjects, getDocuments, companyId]);

  return (
    <>
      {!loading ? (
        <AccountPageWrap>
          <ContextMenu name={company.name} logo={company.logo_path} />
          <ContentContainer>
            <Router primary={false}>
              <MyAccount path="my-account" />
              <CompanyProfile path="company-profile/*" company={company} />
            </Router>
          </ContentContainer>
        </AccountPageWrap>
      ) : (
        <div style={{ padding: 50, flexGrow: 1 }}>
          <Skeleton duration={0.5} count={10} width="100%" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.search.activeCompany,
    loading: state.search.isLoading,
  };
};

export const Account = connect(mapStateToProps, {
  getCompany,
  getPastProjects,
})(AccountComponent);
