import api from "api";
import * as constants from "./actionTypes";
import  React  from 'react';
import { clearSignupData, isEmptyObj, processFormData } from "../utils/helpers";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import { getCompany } from "./companyActions";
import LogRocket from "logrocket";

function getApiWithToken() {
  const token = localStorage.getItem("access_token");
  return api.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("Authorization", `Bearer ${token}`);
        },
      ],
    },
  });
}

export const authenticateUser = (
  values,
  setSubmitting,
  setStatus,
  successMessage
) => {
  return async (dispatch) => {
    (async () => {
      const formData = processFormData(values);

      try {
        const user = await api.post("auth/login", { body: formData }).json();

        if (user.success === true) {
          localStorage.setItem("access_token", user.access_token);
          localStorage.setItem("is_admin", user.is_admin);
          localStorage.setItem("company_admin", user.company_admin || null);

          const userProfile = await getApiWithToken()
            .get("auth/user-profile")
            .json();
          localStorage.setItem("company_id", userProfile.data.company_id);
          localStorage.setItem("user", JSON.stringify(userProfile.data));
          await dispatch({
            type: constants.AUTHENTICATE_USER,
            payload: {
              access_token: user.access_token,
            },
          });
          await dispatch({
            type: constants.GET_USER,
            payload: userProfile.data,
          });
          await dispatch(getCurrentUser());
          toast(successMessage, { containerId: "custom" });
        }
      } catch (e) {
        const error = await e.response?.json();
        setStatus(error?.error || "connection");
      }

      setSubmitting(false);
    })();
  };
};

export const signup = (
  id,
  first_name,
  last_name,
  email,
  password,
  setSubmitting
) => {
  return async (dispatch) => {
    const formData = processFormData({
      id: id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
    });
    try {
      const user = await api
        .post("auth/users/create", {
          body: formData,
        })
        .json();

      if (user.success === true) {
        await dispatch({
          type: constants.GET_SIGNUP_DETAILS,
          payload: user.user,
        });
        return user;
      } else {
        setSubmitting(false);
        return user;
      }
    } catch (e) {
      const error = await e.response.json();
      toast.error(error.error);
    }
  };
};

export const saveBusinessDetails = (
  id,
  company_id,
  business_name,
  phone,
  abn,
  setSubmitting
) => {
  return async (dispatch) => {
    const formData = processFormData({
      id: id,
      company_id: company_id,
      business_name: business_name,
      phone: phone,
      abn: abn,
    });
    try {
      const user = await api
        .post("auth/business/create", {
          body: formData,
        })
        .json();

      if (user.success === true) {
        await dispatch({
          type: constants.GET_SIGNUP_DETAILS,
          payload: user.user,
        });
        return user;
      } else {
        setSubmitting(false);
        return user;
      }
    } catch (e) {
      const error = await e;
      if (error.response.status === 404) {
        let errorMessage =
          "Please start the process again.and refresh the page.If you continue to experience difficulties, please email us at support@procuracon.com.au";
        toast.error(errorMessage);
        clearSignupData();
        return error.response.status;
      } else toast.error(error.response);
      toast.error(error.error);
    }
  };
};

export const setSignupData = (data) => {
  return { type: constants.GET_SIGNUP_DETAILS, payload: data };
};

export const completeRegistration = (values, setSubmitting) => {
  return async (dispatch) => {
    const formData = processFormData({
      id: values.id,
      account_type_id: parseInt(values.account_type_id),
      profile_type_id: values.profile_type_id
        ? parseInt(values.profile_type_id)
        : null,
      payment_method: values.payment_method,
      token_id: values.token_id,
      discount_code: values.discount_code,
      automatic_subscription: values.automatic_subscription,
    });
    try {
      const res = await api
        .post("auth/users/complete-registration", {
          body: formData,
          timeout: false,
        })
        .json();
      if (res.success === true) {
        clearSignupData();
        await dispatch({
          type: constants.GET_SIGNUP_DETAILS,
          payload: {},
        });
        toast.success("Registration Completed Successfully");
        return res;
      } else {
        setSubmitting(false);
        return res;
      }
    } catch (e) {
      const error = await e;
      if (error.response.status===404){
        let errorMessage = "Please start the process again.and refresh the page.If you continue to experience difficulties, please email us at support@procuracon.com.au";
        toast.error(errorMessage);
        clearSignupData();
        return error.response.status;
      } else toast.error(error.response);
    }
  };
};

export const forgotPassword = (values, setSubmitting) => {
  return async () => {
    const formData = processFormData(values);

    try {
      const status = await api
        .post("auth/forget-password", { body: formData })
        .json();

      if (status.status) {
        setSubmitting(false);
        return status;
      } else {
        setSubmitting(false);
        return status;
      }
    } catch (e) {
      const error = await e.response.json();
      console.log(error.error);
    }
  };
};

export const resetPassword = (values, setSubmitting) => {
  return async () => {
    const formData = processFormData(values);

    try {
      const status = await api
        .post("auth/reset-password", { body: formData })
        .json();

      if (status.status) {
        toast.success("Reset Password successfully. Redirecting now.", {
          autoClose: 7000,
        });
        navigate("/login");
      } else {
        toast.error(status.message, {
          autoClose: 7000,
        });
      }
    } catch (e) {
      const error = await e.response.json();
      const errorMessage = error.errors[Object.keys(error.errors)[0]];
      toast.error(errorMessage[0]);
    }

    setSubmitting(false);
  };
};

export const applyDiscount = (id) => {
  return async () => {
    try {
      const result = await api.get(`apply-discount/${id}`).json();
      return result;
    } catch (e) {
      const error = await e.response.json();
      toast.error(error.error);
    }
  };
};

export const createCompanyUser = (values) => {
  return async () => {
    const formData = processFormData(values);
    try {
      const result = await api
        .post("users/create-company-user", {
          body: formData,
        })
        .json();
      if (result.success === true) {
        toast.success("User Created.");
        return result;
      } else {
        toast.error(result.message);
        return result;
      }
    } catch (e) {
      const error = await e.response.json();
      toast.error(error.error);
    }
  };
};

export const verifyEmail = (email, token) => {
  return async (dispatch) => {
    (async () => {
      const formData = processFormData({ email: email, code: token });

      try {
        const res = await getApiWithToken()
          .post("auth/verify-email", { body: formData, timeout: false })
          .json();
        if (res.status === true) {
          const acc_token = localStorage.getItem("access_token");
          if (acc_token) {
            const user = await getApiWithToken()
              .get("auth/user-profile")
              .json();
            localStorage.setItem("company_id", user.data.company_id);
            localStorage.setItem("user", JSON.stringify(user.data));

            await dispatch({
              type: constants.GET_USER,
              payload: user.data,
            });
            toast.success("Email Verified. Redirecting now.");
          } else toast.success("Email has been Verified. Login Now");
          navigate("/login");
        } else {
          toast.dismiss();
          toast.error(res.message);
          const access_token = localStorage.getItem("access_token");
          if (access_token) navigate("/verify-email", { replace: true });
          else navigate("/login", { replace: true });
        }
      } catch (e) {
        const error = await e.response.json();
        toast.error(error.error);
        navigate("/login", { replace: true });
      }
    })();
  };
};

export const setAccessToken = (token) => {
  return async (dispatch) => {
    (async () => {
      await dispatch({
        type: constants.AUTHENTICATE_USER,
        payload: {
          access_token: token,
        },
      });
      await dispatch(getCurrentUser());
    })();
  };
};

export const getCurrentUser = () => {
  return async (dispatch) => {
    (async () => {
      try {
        const user = await getApiWithToken().get("auth/user-profile").json();

        if (user.data && !isEmptyObj(user.data)) {
          localStorage.setItem("company_id", user.data.company_id);
          localStorage.setItem("user", JSON.stringify(user.data));

          if (!LogRocket?._identified) {
            console.info("[LogRocket]", "Identified user: ", user.data);
            LogRocket.identify(user.data.id, {
              name: user.full_name,
              email: user.email,
            });
            LogRocket._identified = true;
          }

          await dispatch({
            type: constants.GET_USER,
            payload: user.data,
          });
          const company = await getApiWithToken()
            .get(
              `companies/${user.data.company_id}?past_projects_published=false`
            )
            .json();
          await dispatch({
            type: constants.GET_CURRENT_COMPANY,
            payload: company.data,
          });
        } else {
          dispatch(logoutUser());
        }
      } catch (e) {
        console.log("error while fetching user", e);
        dispatch(logoutUser());
      }
    })();
  };
};

export const setLoggedInUser = (user) => {
  return async (dispatch) => {
    (async () => {
      await dispatch({
        type: constants.GET_USER,
        payload: user,
      });
      await dispatch(getCompany(user.company_id));
    })();
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    (async () => {
      try {
        const token = localStorage.getItem("access_token");
        const logoutApi = api.extend({
          hooks: {
            beforeRequest: [
              (request) => {
                request.headers.set("Authorization", `Bearer ${token}`);
              },
            ],
          },
        });

        const logout = await logoutApi.post("auth/logout").json();

        if (logout.success === true) {
          localStorage.clear();
          await dispatch({ type: constants.LOGOUT_USER });
          navigate("/login", { replace: true });
        } else {
          toast.error(logout.error);
        }
      } catch (e) {
        const error = await e.response?.json();
        toast.error(error?.error);
      }
    })();
  };
};

export const logoutGuestUser = () => {
  return async (dispatch) => {
    (async () => {
      try {
        localStorage.clear();
        navigate("/guest/login", { replace: true });
        await dispatch({ type: constants.LOGOUT_GUEST_USER });
      } catch (e) {
        const error = await e.response?.json();
        toast.error(error?.error);
      }
    })();
  };
};

export const guestSignup = (values, setSubmitting, setStatus) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    try {
      const res = await api
        .post("guest/register", {
          body: formData,
          timeout: false,
        })
        .json();

      if (res.success === true) {
        toast.success(res.message,{autoClose:10000});
        if (!res.new_user){
          res.guest_user
            ? navigate("/guest/login", { replace: true })
            : navigate("/login", { replace: true });
          return res;
        }
        else {
          navigate("/thank-you");
          return res;
        }
      } else {
        setSubmitting(false);
        return res;
      }
    } catch (e) {
      setSubmitting(false);
      const error = await e.response.json();
      setStatus(error.error || "connection");
      toast.error(error.message);
    }
  };
};
export const guestLogin = (
  values,
  setSubmitting,
  setStatus,
  successMessage
) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    try {
      const res = await api
        .post("guest/login", {
          body: formData,
        })
        .json();

      if (res.success === true) {
        localStorage.setItem("guest_user", JSON.stringify(res.user));
        await dispatch({
          type: constants.AUTHENTICATE_GUEST_USER,
          payload: res.user,
        });
        toast(successMessage, { containerId: "custom" });
        setSubmitting(false);
        return res;
      } else {
        setSubmitting(false);
        return res;
      }
    } catch (e) {
      setSubmitting(false);
      const error = await e.response.json();
      setStatus(error?.message || "connection");
      toast.error(error.message === "suspended"?<div>Your Guest Account is currently suspended.<br />Please contact support@procuracon.com.au</div>: error.message);
    }
  };
};

export const shareProfile = (token) => {

  return async () => {
    try {
      const result = await getApiWithToken().get(`auth/share-profile?invitetoken=${token}`).json();
      return result;
    } catch (e) {
      const error = await e.response.json();
      return error;
    }
  };
};
