import api from "api";

import * as constants from "./actionTypes";
import { processFormData } from "../utils/helpers";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";

function getApiWithToken() {
  const token = localStorage.getItem("access_token");
  return api.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("Authorization", `Bearer ${token}`);
        },
      ],
    },
  });
}
export const getCompanyUsers = (company_id) => {
  return async (dispatch) => {
    (async () => {
      const company_users = await api.get(`all-users/${company_id}`).json();
      await dispatch({
        type: constants.GET_COMPANY_USERS,
        payload: company_users,
      });
    })();
  };
};

export const update_user = (values, profile = false) => {
  values.position === "administrator"
    ? (values["company_admin"] = 1)
    : (values["company_admin"] = 0);
  if (!values.password) {
    delete values["password"];
  }
  return async (dispatch) => {
    const formData = processFormData(values);
    try {
      const result = await api
        .patch(`users/${values.id}`, {
          body: formData,
        })
        .json();
      const user = result.data;
      if (profile && user) {
        localStorage.setItem("user", JSON.stringify(user));
        await dispatch({
          type: constants.GET_USER,
          payload: user,
        });
        toast.success("Details saved successfully.");
        navigate("account-users");
        return user;
      } else {
        toast.success("Details saved successfully.");
        navigate("../account-users");
        return user;
      }
    } catch (e) {
      const error = await e.response.json();
      toast.error(error.error);
    }
  };
};

export const allTransaction = (company_id) => {
  return async (dispatch) => {
    (async () => {
      try {
        const all_transactions = await getApiWithToken()
          .get(`all-transaction/${company_id}`)
          .json();
        await dispatch({
          type: constants.TRANSACTION_DETAILS,
          payload: all_transactions.data,
        });
      } catch (e) {
        console.log(e.response);
      }
    })();
  };
};

export const delete_user = (id) => {
  return async () => {
    try {
      const user = await api.delete(`users/${id}`).json();
      if (user.status === "success") {
        toast.success("User Deleted Successfully");
        return user;
      } else {
        return user;
      }
    } catch (e) {
      const error = await e.response;
      toast.error(error.error);
    }
  };
};

export const delete_plan = (id) => {
  return async (dispatch) => {
    const formData = processFormData({
      account_type_id: null,
    });

    const company = await getApiWithToken()
      .patch(`companies/${id}`, { body: formData })
      .json();
    await dispatch({
      type: constants.SUBMIT_COMPANY_PROFILE_FORM,
      payload: company.data,
    });
    toast.success("Plan Updated Successfully");
    return company;
  };
};

export const create_draft = (values) => {
  return async (dispatch) => {
    const formData = processFormData(values);
    try {
      const user = await getApiWithToken()
        .post("user-draft/create", {
          body: formData,
        })
        .json();
      if (user.success) {
        toast.success("Details Saved Successfully");
      }
    } catch (e) {
      const error = await e.response.json();
      toast.error(error.error);
    }
  };
};

export const cancel_subs = async (mes) => {
  const formData = new FormData();
  formData.append("cancellation_reason", mes);
  try {
    const canceled = await getApiWithToken()
      .post("subscription/cancel", {
        body: formData,
        timeout: 60000,
      })
      .json();
    toast.success(
      "Subscription cancelled. All Admin Users will be notified via email of this cancellation",
      { autoClose: 5000 }
    );
    return canceled;
  } catch (e) {
    console.log("errorcancel", e);
    toast.error("Cancelling Subscription Failed", { autoClose: 5000 });
    throw e;
  }
};

export const restart_subs = () => {
  return async (dispatch) => {
    try {
      const restarted = await getApiWithToken()
        .patch("subscription/restart", {})
        .json();
      await dispatch({
        type: constants.SET_ACCOUNT_STATUS,
        payload: null,
      });
      toast.success("Subscription Restarted", { autoClose: 5000 });
      return restarted;
    } catch (e) {
      console.log("errorcancel", e);
      toast.error(" Restarting Subscription Failed", { autoClose: 5000 });
      throw e;
    }
  };
};

export const setAccountStatus = (status) => {
  return async (dispatch) => {
    await dispatch({
      type: constants.SET_ACCOUNT_STATUS,
      payload: status,
    });
  };
};
