import styled from 'styled-components/macro';
import { Modal } from "components/Modal";

export const UpdatePlanStyled = styled.div`

.container {
    position:relative;
}
.instruction {
    color: ${(props) => props.theme.colors.grayLight};
}

.bold {
    font-weight: 600;
}

`

export const ConfirmCancelStyled = styled(Modal)`
  padding: 1.5rem 2rem;
  background-color: white;
  min-width: 570px;
  .confirm-btn {
    float: right;
    padding: 8px 12px;
    color: white;
    background: #0045F5;
    border: 1px solid #0045F5;
    cursor: pointer;
    display: flex;
    padding: 8px 35px;
    align-items: center;
    justify-content: center;
    // width: 218px;
    // height: 48px;
    font-size: 14px;
  }
  .cancel-btn {
    float: right;
    padding: 8px 12px;
    color: #0045f5;
    background: transparent;
    border: 1px solid #263859;
    cursor: pointer;
    display: flex;
    padding: 8px 35px;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: end;
  gap: 1.5rem;
  margin: 1.5rem rem 0.5rem 0.5rem 0.5rem;
`;

export const DialogConfirm = styled.h2`
  font-size: 1.15rem;
  width: 95%;
  margin: 1.5rem 0;
  font-weight:500;
  >;
`;

