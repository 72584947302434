import styled from 'styled-components/macro';

export const ProjectGroupStyled = styled.div`
    width: 100%;

    h4 {
        font-weight: 500;
        color: ${(props) => props.theme.colors.grayDark};
        margin: 0 0 0.5rem;
        font-size: ${(props) => props.theme.fontSizes.md};
    }

    p {
        margin-bottom: 0.5rem;

        span {
            color: ${(props) => props.theme.colors.grayDark};
        }
    }

    > div {
        &:first-of-type {
            ${'' /* padding-top: 0; */}
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }
    .section-summary{
        .ql-editor{
            padding: 12px 0;
            max-height: 104px;
            overflow: hidden;
        }
        p{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
        }
    }
`;

export const Project = styled.div`
    border-bottom: 1px dashed ${(props) => props.theme.colors.blueGrey};
    padding: 1.25rem 0;
    min-height: 390px;
    max-height: 390px;
    display: flex;
    flex-direction: column;
`;

export const AddButton = styled.div`
    border-bottom: 1px dashed ${(props) => props.theme.colors.blueGrey};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 390px;
    max-height: 390px;
`;

export const ImageGrid = styled.div`
    display: flex;
    margin-top: auto;
`;

export const Image = styled.img`
    width: 110px;
    height: 80px;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 21px;
`;
