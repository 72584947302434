import styled from 'styled-components/macro';


export const EditUserAccountStyled = styled.div`

    h3{
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #253858;
    }

    .btn-row{
        margin-bottom:40px;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        .save-btns{
            display: flex;
            .save-btn{
                margin-right: 24px;
            }
        }
        .delete-btn{
            background: #FE0202;
            border: 1px solid #FE0202;
           
        }
    }


`