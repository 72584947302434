import React from "react";

import { ReactComponent as DocumentIcon } from "../../icons/document.svg";
import { ReactComponent as ExpiredIcon } from "../../icons/expire.svg";
import { ReactComponent as Check } from "../../icons/small-check.svg";
import { DocumentItemStyled } from "./styled";
import { shortenDigits } from "../../utils/helpers";
import ReactTooltip from "react-tooltip";

export const DocumentItem = ({
  document,
  title,
  children,
  noIcon,
  check,
  current_company,
  ...props
}) => {
  const cross = props.cross;
  let documentName =
    (props.editable || document.display === "public") && document.storage_path
      ? document.name
      : title
      ? title
      : document.name;
  let documentNameArr = documentName.split(" ");
  let lastWord = documentNameArr[documentNameArr.length - 1];

  const isUnderInsuranceCerts = () => {
    return document.category?.parent_id === 4 || document.category?.id === 4;
  };

  if (
    isUnderInsuranceCerts() &&
    document.display === "public" &&
    lastWord.includes("$") === false
  ) {
    documentName =
      documentName + " $" + shortenDigits(document.extra_info.value);
  } else if (
    document.display === "private" &&
    documentNameArr.length > 1 &&
    documentNameArr[documentNameArr.length - 2].includes("$") === true
  ) {
    documentNameArr.splice(documentNameArr.length - 2, 1);
    documentName = documentNameArr.toString().replaceAll(",", " ");
  }

  const messageExpired = () =>{
    let message;
    switch(props?.parentTitle){
      case "Insurance Certificates":  
      message = "Insurance Certificates";
      break;
      default: message = "Document";
    }
    return message
  } 

  return (
    <DocumentItemStyled {...props}>
      {!noIcon && <DocumentIcon />}
      {document?.isExpireSoon && 
      <div className="alert-icon">
        <ExpiredIcon data-tip data-for={`expire-${document.id}`} />
        <ReactTooltip
            className="guide"
            id={`expire-${document.id}`}
            place="top"
            arrowColor="transparent"
          >
            {messageExpired()} is Expire soon
        </ReactTooltip>
      </div>}
      {document && (
        <>
          {(props.editable ||
            document.display === "public" ||
            document?.approved_companies?.includes(current_company?.id)) &&
          document.storage_path ? (
            <a
              href={document.storage_path}
              target="_blank"
              rel="noopener noreferrer"
              className="document__title"
            >
              {documentName} {check ? <Check /> : cross ? <>&mdash;</> : ""}
            </a>
          ) : (
            <p className="document__title">
              {documentName} {check ? <Check /> : cross ? <>&mdash;</> : ""}{" "}
            </p>
          )}

          {children}
        </>
      )}
    </DocumentItemStyled>
  );
};
