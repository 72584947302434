import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import {
  AccountManagementStyled,
  PlanSection,
  InvoicesSection,
  CancelLabel,
  RestartLabel,
} from "./styled";
import { Link } from "@reach/router";
import {
  allTransaction,
  restart_subs,
} from "../../actions/AccountManagementActions";
import { profileTypeOptions } from "../../utils/helpers";
import { setAccountStatus } from "../../actions/AccountManagementActions";
import { getAccountTypes } from "../../actions/accountTypeActions";
import { getCompanyUsers } from "../../actions/AccountManagementActions";
import { updateSubscription } from "actions/companyActions";
import { TabHeader } from "../TabHeader";
import { TabContent } from "../Tabs/styled";
import { FlashError } from "../FormInputs/FlashError";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import { RestartSubscriptionModal } from "components/RestartModal";
import { InvoiceTable } from "components/InvoiceTable";
import { dateParser } from "utils/dateFormat";
import ChangeSubscriptionModal from "./ChangeSubscriptionModal";

export const AccountManagementComponent = ({
  company,
  viewOnly,
  allTransaction,
  getAccountTypes,
  getCompanyUsers,
  current_user,
  setAccountStatus,
  restart_subs,
  updateSubscription,
  account_types,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpenSubs, setIsOpenSubs] = useState(false);

  useEffect(() => {
    allTransaction(company.id);
    getCompanyUsers(company.id);
    getAccountTypes();
  }, [allTransaction, getAccountTypes, getCompanyUsers, current_user, company]);

  const bussiness_profile_type = profileTypeOptions.find(
    (item) => item.value === company.profile_type.id
  );

  const company_id = localStorage.getItem("company_id");

  const expiredCheck = (date) => {
    const present = new Date();
    const billingDate = dateParser(date);
    if (billingDate < present) return true;
    else return false;
  };

  const dummy_payment = {
    card_type: "Card Number",
    last4: "4242",
  };

  const getPlanLabel = (packageName) => {
    const index = account_types?.findIndex(
      (type) => type.package_name === packageName
    );
    return account_types[index]?.label;

  };

  return company ? (
    <AccountManagementStyled>
      <TabHeader heading="Account Management" />
      <TabContent>
        {!company.subscription_status && (
          <FlashError
            heading={"Payment Transaction Declined"}
            text={`We've noticed there is a problem with your payment details.
                    Please check and update all card information including
                    expiry date and CVV (security code) as soon as possible so
                    you can continue using Procuracon.`}
            margin="-16px 0px 40px 0px"
          />
        )}
        <PlanSection>
          <div className="plan-container ">
            <div className="plan-row">
              <h6>Subscription</h6>
              <p>
                <span>
                  {company?.plan
                    ? getPlanLabel(company?.plan)
                    : "No Plan Selected"}
                </span>{" "}
                {/* {company.next_billing_date ? (
                  <>

                    --- on hold ---
                    {company.account_status !== "cancelled" && (
                      <Link className="plan-link" to={`edit-subscription`}>
                        Edit Subscription
                      </Link>
                    )}

                      <RestartLabel onClick={() => setIsOpenSubs(true)}>
                        Change Subscription Plan
                      </RestartLabel>
                  </>
                ) : (
                  <div className="plan-link">{"Edit Subscription"}</div>
                )} */}
                <RestartLabel onClick={() => setIsOpenSubs(true)}>
                    Change Subscription Plan
                </RestartLabel>
              </p>
            </div>
            {company.profile_type.id !== 1 && (
              <div className="plan-row">
                <h6>Business Profile Type</h6>
                <p>{bussiness_profile_type?.label || <>&mdash;</>}</p>
              </div>
            )}

            <div className="plan-row">
              <h6>Account Expires</h6>
              <p>
                {company.next_billing_date ? (
                  <>
                    {expiredCheck(company.next_billing_date) ||
                    company.account_status === "cancelled"
                      ? "--/--/--"
                      : company.next_billing_date}
                    {!expiredCheck(company.next_billing_date) &&
                      (company.account_status === "cancelled" ? (
                        <RestartLabel onClick={() => setIsOpen2(true)}>
                          Restart Subscription
                        </RestartLabel>
                      ) : (
                        <CancelLabel onClick={() => setIsOpen(true)}>
                          Cancel Subscription
                        </CancelLabel>
                      ))}
                  </>
                ) : (
                  <>{"--/--/-- (next billing date is empty)"}</>
                )}
              </p>
            </div>
            <div className="plan-row">
              <h6>Payment Method</h6>
              <p>
                {company.last4
                  ? `${
                      company.payment_method
                        ? "Card Number"
                        : dummy_payment.card_type
                    } (Last 4 digits: ${company.last4})`
                  : "Not Specified"}

                {dummy_payment.card_type === "Card Number" && (
                  <Link
                    className="plan-link"
                    to={`/account/${company_id}/company-profile/account-management/update-card-details`}
                  >
                    Update Your Payment Details
                  </Link>
                )}
              </p>
            </div>
          </div>
          <div></div>
        </PlanSection>

        <InvoicesSection>
          <h2 className="transaction-head">Transactions</h2>
          <InvoiceTable datas={props.invoices} accountTypes={account_types} />
        </InvoicesSection>
      </TabContent>
      <CancelSubscriptionModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setAccountStatus={setAccountStatus}
      />
      <RestartSubscriptionModal
        isOpen={isOpen2}
        setIsOpen={setIsOpen2}
        restart_subs={restart_subs}
        currentInvoice={props.invoices[0]}
      />

      <ChangeSubscriptionModal
        isOpen={isOpenSubs}
        setIsOpenSubs={setIsOpenSubs}
      />
    </AccountManagementStyled>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const current_user = state.auth.user;
  const invoices = state.accountManagement.transactionData;
  const account_types = state.accountTypes.accountTypeData;
  const company_users = state.accountManagement.companyUsers;
  console.log("datas", state);
  return {
    isRequesting: state.companyProfile.isRequesting,
    company: company,
    current_user: current_user,
    invoices: invoices,
    account_types: account_types,
    company_users: company_users,
  };
};

export const AccountManagement = connect(mapStateToProps, {
  allTransaction,
  getAccountTypes,
  getCompanyUsers,
  setAccountStatus,
  restart_subs,
  updateSubscription,
})(AccountManagementComponent);
