import React, { useEffect, useState } from "react";
import { PackageCard } from "components/PackageCard/PackageCard";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { Grid } from "@chakra-ui/core";
import { Button } from "components/Button";
import { Select } from "components/FormInputs/Select";
import Skeleton from "react-loading-skeleton";
import { profileTypeOptions } from "utils/helpers";
import { UpdatePlanStyled } from "./styled";
import { FlashError } from "components/FormInputs/FlashError";
import { OnSubmitValidationError } from "utils/OnSubmitValidationError";
import ConfirmationModal from "./ConfirmationModal";



export const UpdatePlanDetail = ({
  accountTypes,
  setAccountType,
  profileType,
  nextBillingDate,
  calculation,
  getCalculation,
  getAccountTypes,
  updateSubscription,
  company,
    ...props
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [idClicked, setIdClicked] = useState(company.account_type_id);
    const prevId = company.account_type_id;

    useEffect(() => {
      getAccountTypes();
      if(!calculation){
        getCalculation();
      }
    }, [getAccountTypes, getCalculation, calculation]);

    const handleSubmit = async (values, setSubmitting, setStatus, setIsOpen ) => {
      try {
        if(values.account_type_id === 1 && idClicked === 1){
          values.profile_type_id = 1;
          updateSubscription(values, setSubmitting, prevId, accountTypes, setStatus, setIsOpen);
        }
        else{
          updateSubscription(values, setSubmitting, prevId, accountTypes, setStatus, setIsOpen);
        }
      }
      catch (e) {
        const error = await e;
        console.log("error occured", error);
        setStatus("Stripe");
      }
      if(!values.account_type_id || !values.profile_type){
        setSubmitting(false);
      }
    };

    const disabled = (values) => {
      if(values.profile_type_id === " "){
        return true;
      }
      if(prorateAmount && calculation.total_amount[idClicked - 1].total_amount === 0){ 
       if(profileType.id === 1 && idClicked === 1){
        return true;
       }
        if(values.profile_type_id !== profileType.id){
          return false;
        };
        return true;
      }
      if(values.profile_type_id === " " || (prorateAmount && calculation.total_amount[idClicked - 1].total_amount === 0)){
        if(values.profile_type_id !== profileType.id){
          return false;
        };
      if(prorateAmount && calculation.total_amount[idClicked - 1].total_amount === 0) {
        if(values.profile_type_id === " "){
          if(values.profile_type_id === profileType.id){
            return true;
          }
          return true;
        }
        return true;
      }
      return true;
    }
  
  };

    const prorateAmount = "total_amount" in calculation;
    const dateParts = nextBillingDate? nextBillingDate.split("/"): "";
    const dateObject = nextBillingDate? (new Date(+ dateParts[2], dateParts[1] - 1, + dateParts[0])):""; 

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const renderPackages = (setStatus) => {
      return accountTypes?.map((plan, i) => (
        <>
          <PackageCard
          key={i}
          label={plan.label}
          name="account_type_id"
          amount={plan.total_amount}
          value={plan.id}
          textInfo={plan.description}
          handleOnClick={()=> {
            setStatus(null)
            setIdClicked(plan.id)
          }}
          current={company.account_type_id}
        />
        </>       
      ));
    };
    const updateProfileTypeOptions = profileTypeOptions.filter(
      (item) => item.value !== 1
    );

    const curentProfileType = (values) => profileTypeOptions.filter(
      (item) => item.value === (!values.profile_type_id?profileType.id : values.profile_type_id) && item.value !== 1
      
    );
     

    return (
        <>
        <UpdatePlanStyled>
        <div className="container">
            <Grid>
            <div className="row">
            <div className="col xl-2"/>
            <div className="col xl-8">
            <div className="instruction">
                <p>
                We understand that your needs may change, and we are here to 
                accommodate them! You can modify your subscription plan 
                according to your preferences and requirements. 
                Please review and select your desired plan to proceed with the changes.
                </p>
                <br />
                <p className="bold">
                Important Note:
                </p>
                <p className="bold">
                
                When you update your subscription,
                you will be charged a prorated amount for 
                remainder of the billing cycle. 
                This means that you will only pay 
                the difference in cost between your 
                old and new plans for time left in
                your current billing period. 
                The updated pricing will be reflected in your next billing cycle.
                </p>

            </div>
            <Formik
                enableReinitialize
                initialValues={{
                  account_type_id: " ",
                  profile_type_id: prevId === 1?" ":profileType.id === 1?" ":profileType.id
                }}
                validationSchema={Yup.object({
                  account_type_id: Yup.number().required(),
                  profile_type_id: idClicked === 1?Yup.string():Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().required()
                })}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                  if (
                    (values.account_type_id === 2 || values.account_type_id === 3) &&
                    values.profile_type_id === null
                  ) {
                    setStatus("Profile");
                    setSubmitting(false);
                    return;
                  }
                  handleSubmit(values, setSubmitting, setStatus, setIsOpen);
                  setIsOpen(true);
                }}
              >
                {(props) => {
                  const { setFieldValue, isSubmitting, values, setStatus, status } =
                    props;
                    console.log("submit", isSubmitting)
                    const onSubmitValidationError = () => {
                      setStatus("Account");
                    };
                  return (
                    <Form>
                      <div className="row">
                      <div className="col xl-12">
                      {accountTypes.length ? (
                            renderPackages(setStatus, values.account_type_id)
                          ) : (
                            <Skeleton count={5} duration={0.5} />
                      )}

                      </div>

                        {(values.account_type_id === 2 ||
                          values.account_type_id === 3) && (
                            <>
                            <Select
                            id={"profile_type_id"}
                            label="Profile Type"
                            required="true"
                            name="profile_type_id"
                            className="col xl-12 uptid"
                            setFieldValue={setFieldValue}
                            options={updateProfileTypeOptions}
                            placeholder={"Choose your Profile Type"}
                            errorText={
                              (values.account_type_id !== 1 || profileType.id === null) && status 
                                ? `Profile type is required`
                                : null
                            }
                            handleOnMenuOpen={() => setStatus(null)}
                            defaultValue={curentProfileType(values)}
                          />
                          {console.log("values", values)}
                          {console.log("different profile", typeof profileType.id )}
                            </>

                        )}
                        <div className="col xl-12 p-0">
                        <div className="instruction">
                          <p>
                          Based on your current billing cycle, a prorated charged of ${prorateAmount?calculation.total_amount[idClicked-1].total_amount : "0"} will be applied to your account. This amount represents the cost difference between your old and new plans for remaining days in current billing cycle, from today to {nextBillingDate?dateObject.toLocaleDateString("en-US",options):" - "}.
                          </p>                          
                        </div>
                        </div>
                        <div className="col xl-12 p-0">
                        {!values.account_type_id && status !== "Stripe" && (
                          <FlashError
                            heading={`* ${status} Type Required`}
                            text={`Please select ${status} type.`}
                            margin="10px 0px 40px 0px"
                          />
                        )}
                        {status === "Stripe" && 
                        <FlashError
                            heading={`Payment Failed`}
                            text={`We're sorry, but your payment transaction has been declined. Please double-check your payment details or try using a different card. If the issue persists, please contact your card issuer for more information or assistance. If you continue to experience difficulties on our end, please email us at support@procuracon.com.au`}
                            margin="10px 0px 10px 0px"
                      />

                        }
                        </div>
                        {console.log("status error", status)}
                        <div className="col xl-12 p-0">
                          <Button
                            type="button"
                            widthExpand
                            onClick={() => setIsOpen(true)}
                            marginTop={"40px"}
                            marginBottom={"40px"}
                            disabled={disabled(values)}
                            >
                            Update Subscription
                            
                          </Button>
                          {isOpen  &&
                          <ConfirmationModal
                            prevId={prevId}
                            idClicked={idClicked}
                            setIsOpen={setIsOpen}
                            isSubmitting={isSubmitting}
                            isOpen={isOpen}
                            values={values}
                            profileType={profileType}
                          />
                          
                          }
                          {console.log("is open", isOpen)}
            
                        <OnSubmitValidationError callback={onSubmitValidationError} />
                        
                        </div>
                      </div>

                    </Form>
                  );
                }}
            </Formik> 
            </div>
            <div className="col xl-2"/>
            </div>
            </Grid>
          </div>
        </UpdatePlanStyled>    
        </>


    );
  };



