import styled, { css } from 'styled-components/macro';

const TextAreaStyles = css`
    border-radius: 5px;
    margin-bottom: 1.25rem;
    position: relative;
    border: 1px solid rgba(132, 146, 166, 0.2);
`;

export const TextAreaStyled = styled.textarea`
    background: white;
    width: 100%;
    color: ${(props) => props.theme.colors.gray};
    min-height: 140px;
    transition: ${(props) => props.theme.transitions.default};
    line-height: 1.5;
    resize: none;
    padding: 1.25rem;
    border: none;

    ${'' /* don't want to double up on border rules*/}
    ${(props) =>
        !props.maxLength &&
        `
        ${TextAreaStyles}
    `}

    ::-webkit-input-placeholder {
        color:#6F7C8F;
        font-size: ${props=>props.placeholderSize};
    }

    &:focus {
        outline: none;
        border-color: ${(props) => props.theme.colors.royalBlueLight};
    }
`;

export const TextAreaContainer = styled.div`
    ${TextAreaStyles}
    padding-bottom: ${(props) => props.theme.space.sm_2};
    margin-bottom:${props=> props.isError ? '2.15rem' : '1.75rem'};
    border-color: ${props=>props.isError ? '#E11900' : ''};
`;

export const WordCountStyled = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    color: #c6cdd5;
    position: absolute;
    bottom: 0.15rem;
    right: 1rem;
    font-size: ${(props) => props.theme.fontSizes.sm};
    pointer-events: none;
`;
