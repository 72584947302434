import styled from "styled-components/macro";

export const PageWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  margin-right: auto;
  padding-top: 30px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "white")};
  min-height: 100vh;
`;
