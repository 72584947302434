import styled, { createGlobalStyle } from "styled-components/macro";
import ReactModal from "react-modal";

export const ModalTransitionStyles = createGlobalStyle`
    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 200ms ease-in-out;
        overflow-y: scroll;
    }


    .ReactModal__Overlay--after-open {
        opacity: 1;
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
    }

    .ReactModal__Content {
        margin-top: 45px;
        margin-bottom: 45px;
        top: 0;
    }
`;

export const ModalInner = styled.div`
  transition: all 500ms ease;
  transform: translateY(${(props) => (props.isOpen ? "0" : "5px")});
  opacity: (${(props) => (props.isOpen ? "1" : "0")});
  max-height: 100vh;
  overflow-y: ${(props) => (props.scroll ? "auto" : "initial")};
  position: relative;
  background-color: white;

  @keyframes modalSlide {
    from {
      transform: translateY(25px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  animation-name: modalSlide;
  animation-duration: 500ms;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 24px;
  top: ${(props) => (props.top ? props.top : "30px")};
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;

  &:focus {
    outline: none;
  }
`;

export const ModalStyled = styled(ReactModal)``;
