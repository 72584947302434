import React, { useState } from "react";
import { Heading } from "../Heading";
import { Button } from "components/Button";
import { Spinner } from "@chakra-ui/core";

import { ConfirmCancelStyled, ButtonRow, DialogConfirm } from "./styled";

export const RestartSubscriptionModal = ({
  isOpen,
  setIsOpen,
  restart_subs,
  currentInvoice,
}) => {
  const [sending, setSending] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const restartHandle = async () => {
    setSending(true);
    try {
      await restart_subs().then((res) => {
        setSending(false);
        setIsOpen(false);
      });
    } catch (e) {
      const error = await e;
      setSending(false);
      setIsOpen(false);
      console.log(error);
    }
  };

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formatSeperator = (number) => {
    return Number(number).toLocaleString("en", options);
  };

  return (
    <ConfirmCancelStyled
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      width="650"
      height="570px"
      closeIconHeight="24px"
      closeIconWidth="24px"
      closeIconTop="20px"
    >
      <Heading>Confirmation</Heading>
      <DialogConfirm>
        <p>
          {`We’re delighted to have you back! Your new subscription begins today
          for another year, and the total amount for this period is $
          ${formatSeperator(
            currentInvoice?.charged_amount / 100 - currentInvoice?.gst
          )} (Excl. GST)`}
        </p>
        <p>Please confirm to proceed with this renewal.</p>
      </DialogConfirm>
      <ButtonRow>
        {!sending && (
          <Button
            size={"md"}
            danger
            className="cancel-btn"
            btntype={"secondary"}
            width={"218px"}
            height={"48px"}
            textfont={"14px"}
            boxshadow={false}
            onClick={handleClose}
            type="button"
          >
            Close
          </Button>
        )}
        <Button
          size={"md"}
          danger
          className="confirm-btn"
          width={"218px"}
          height={"48px"}
          textfont={"14px"}
          boxshadow={false}
          onClick={restartHandle}
          type="button"
          disabled={sending}
          style={
            sending
              ? {
                  background: "#E9ECF1",
                  color: "#8492A6",
                  borderColor: "#d2d5da",
                }
              : {}
          }
        >
          {sending ? <Spinner /> : "Confirm"}
        </Button>
      </ButtonRow>
    </ConfirmCancelStyled>
  );
};
