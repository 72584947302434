import React from 'react';
import styled from 'styled-components/macro';

const FormContainerStyled = styled.div`
    background-color: white;
    padding: ${(props) => props.theme.space.md};
    width: calc(100% - 60px);
    max-width: 1200px;
    margin: 0 ${(props) => props.theme.space.md};
`;

export const Divider = styled.span`
    display: block;
    width: calc(100% + 2.5rem);
    height: 1px;
    background-color: ${(props) => props.theme.colors.snowDark};
    margin: 1rem -1.25rem 2.25rem;
`;

export const FormContainer = (props) => <FormContainerStyled>{props.children}</FormContainerStyled>;
