import styled from "styled-components/macro";

export const GuestSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.snow};
  padding: 0rem 0.85rem;
  padding-right: 0;
  left: 90px;
  width: 310px;
  position: fixed;
  height: 100vh;
  flex-shrink: 0;

  .company-list {
    overflow: auto;
    max-height: 350px;
  }

  hr {
    border-top: 1px solid #5b6b88;
    opacity: 0.2;
  }

  .active-link {
    svg {
      color: ${(props) => props.theme.colors.royalBlue};
      rect {
        stroke: ${(props) => props.theme.colors.royalBlue};
      }
    }
  }
`;

export const InfoWrapper = styled.div`
  padding: 24px 16px;
  display: flex;
  column-gap: 16px;
  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
  font-size: 12px;
  span {
    color: ${(props) => props.theme.colors.navy};
    a {
      text-decoration: underline;
    }
  }
`;

export const PaidFeaturesWrapper = styled.div`
  margin-top: auto;
  a {
    font-weight: 400;
  }
  .account-details {
    padding: 32px 16px;
    .details {
      p {
        font-size: 15px;
        font-weight: 400;
        color: ${(props) => props.theme.colors.navy};
        margin: 0;
      }
      .email {
        font-size: 18px;
        font-weight: 500;
      }
      margin-bottom: 24px;
    }
  }
`;
